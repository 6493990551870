@import "../inc/base";

/**
*   Un bandeau est un conteneur pour une série d'éléments de type article, produit, magazine. etc
*/


.bandeau {
    margin-bottom: var(--marge);
    @include marginInline(calc(var(--marge-exterieure-grille) * -1));

    @include grand {
        margin: 0;
        margin-left: calc(var(--marge) * -1);
    }

    background: var(--color-brand);
    color: var(--color-brand-alt);
    display: flex;
    @include columnGap();

    &.bandeau-horizontal>div {
        @include gap();

        >a {


            flex: 0 0 20vw;

            @include moyen {
                flex: 0 0 7vw;
            }
        }
    }


    >h2 {
        margin: 0;
        text-transform: uppercase;
        font-family: var(--police-serif);
    }

    padding: var(--marge) var(--marge);

    >div {
        display: flex;
        justify-content: space-between;
        overflow-x: auto;

        padding-bottom: var(--marge);

        @include moins-que-grand {
            @include gap();
        }



        >a {
            display: block;
            overflow: hidden;
            flex: 0 0 27vw;

            @include petit {
                flex: 0 0 23vw;
            }

            position: relative;

            >figure {
                margin: 0;
                position: relative;
                overflow: hidden;

                >img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    &[data-mode="couverture"]>div>a {
        >figure {
            width: 100%;
            aspect-ratio: 160/190;

            // width: 160px;
            // height: 185px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &[data-mode="produit"]>div>a {
        display: flex;
        @include columnGap(var(--marge-mini));
        // flex-direction: column;
        // @include gap(var((--marge-mini)));

        figure, picture {
            width: 100%;
            aspect-ratio: 1;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        >span {
            color: var(--color-brand-alt);
            text-align: center;

            @include moyen {
                display: none;
            }
        }

        &:hover>figure>img {}
    }

    &:not(.bandeau-horizontal) {
        @include grand {
            >div {
                >a {
                    &:nth-child(n+5) {
                        display: none;
                    }
                }
            }
        }

        @include tres-grand {
            >div {
                >a {
                    &:nth-child(n+6) {
                        display: none;
                    }
                }
            }
        }

        @include grand {
            padding: var(--marge) 0;

            // margin: 0 calc(var(--marge) * -1);
            margin-top: calc(var(--border-size-1) * -1); // on remonte de la hauteur de la bordure, pour la masquer
            display: flex;

            >div {
                margin: calc(var(--marge) * 2);
                margin-top: var(--marge);
                flex: 1;
                // flex-direction: column;
                @include columnGap();

                >a {
                    flex: 0 0 auto;

                }
            }

            >h2 {
                text-align: center;
                font-family: var(--police-serif);
                font-size: var(--taille-texte-smart);
            }

            &[data-mode="couverture"]>div>a>figure {
                width: 100%;
                aspect-ratio: 160/190;
                // height: 0;
                // padding-bottom: 131%;

            }

            &[data-mode="produit"]>div>a {
                >figure {
                    display: block;
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                }
            }


        }
    }
}

.grille {
    .bandeau:not(.bandeau-horizontal) {
        @include grand {
            grid-row: span 3;
        }

        @include tres-grand {
            grid-row: span 5;
        }
    }
}