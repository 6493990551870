@import "../inc/base";

.field[data-special="true"] {
    display: none !important;
}

input[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
}


form {
    margin: 0;
    padding: 0;
}

fieldset {
    margin: 0 0 var(--marge-petite) 0;
    padding: 0;
    border: 0;
}

label {
    margin: 0;
    font-family: var(--police-sans-serif);
    font-weight: var(--font-weight-bold);
    color: var(--color-input-text);
}

.field {
    --message-color: var(--color-dark);
    --message: '';

    .input {}

    display: flex;
    @include columnGap(var(--marge-micro));
    // flex-direction: column;
    // @include gap(var(--marge-micro));

    &[data-success] {
        --message-color: var(--success-color);
        --message: attr(data-success);
    }

    &[data-error] {
        --message-color: var(--error-color);
        --message: attr(data-error);
    }
    &[data-warning] {
        --message-color: var(--warning-color);
        --message: attr(data-warning);
    }

    &[data-success],
    &[data-warning],
    &[data-error] {
        &:after {
            color: var(--message-color);
            content: var(--message);
            font-weight: normal;
            font-variation-settings: "wght"800;
            font-family: var(--police-sans-serif);
            font-size: var(--taille-texte-petit);
            display: inline-block;
            display: block;
        }

        div.input,
        select.select,
        textarea.input,
        input.input {
            border-color: var(--message-color)
        }

    }

}


form.form {
    @include grid();
    margin: var(--marge-grande) 0;

    >p,
    .fieldset,
    .buttons {
        margin: 0;
        @include span(('all':'content', 'moyen':6, 'grand':8));
    }

    .fieldset {
        @include span(('all':'content', 'moyen':6, 'grand':8, 'grid':true));
        border-left: var(--border-size-2) solid var(--color-mid);
        padding-left: var(--marge);
        @include paddingBlock(var(--marge));
    }

    .buttons {}

    >h1,
    h2,
    h3,
    h4,
    h5 {
        @include span(('all':'content', 'moyen':6, 'grand':8));
        margin: 0;
    }

    .field {
        @include span(('all':'content', 'moyen':6, 'grand':8));

        >.input {
            margin: 0;
        }
    }

    .fields {
        @include span(('all':'content', 'moyen':6, 'grand':8));
        display: flex;
        // flex-direction: column;
        // @include gap();
        @include columnGap();

        >div {
            display: flex;
            @include gap();

            >.field {
                flex: 1;
            }
        }
    }
}

div.input,
select.select,
textarea.input,
input.input {
    // margin-bottom: var(--marge-petite);
    background-color: var(--color-input-background);
    border: var(--border-size-1) solid var(--color-input-border);
    border-radius: var(--radius-2);
    font-family: var(--police-sans-serif);
    font-size: var(--taille-texte-petit);
    transition: all var(--duration-quickly) ease;
}

div.input>input,
textarea.input,
input.input {
    outline: 0;
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    border-radius: 0;
}

textarea.input,
input.input {
    padding: var(--marge-tres-petite);
    display: block;
}

textarea.input {
    min-height: 12vh;
    resize: vertical;
}

textarea.input,
input.input {
    &:focus {
        border: var(--border-size-1) solid var(--color-mid);
    }
}

/* errors */

div.input.error,
textarea.input.error,
input.input.error {
    border: var(--border-size-1) solid var(--color-negative);
    color: var(--color-negative);
}

/* Select */

select.select,
div.input>select {
    position: relative;
    appearance: none;
    display: block;
    box-sizing: border-box;
    padding: var(--marge-petite) calc(var(--marge-petite)*3) var(--marge-petite) var(--marge-petite);
    width: 100%;
    background-color: var(--color-input-background);
    font-size: var(--taille-texte-petit);
		font-weight: var(--font-weight-medium);
    color: var(--color-input-text);
    transition: all var(--duration-quickly) ease;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='7px' viewBox='0 0 11 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='inputs' transform='translate(-682.000000, -663.000000)' fill='%236F707C'%3E%3Cg id='Select/Hover' transform='translate(527.000000, 646.443224)'%3E%3Cpolygon id='Triangle' transform='translate(160.500000, 20.000000) scale(1, -1) translate(-160.500000, -20.000000) ' points='160.5 17 166 23 155 23'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-position: right 10px top 50%;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.08);
}



div.input {
    display: flex;
    @include gap(var(--marge-micro));

    >* {
        padding: var(--marge-micro) var(--marge-tres-petite);
        margin: 0;
        border: 0;
        outline: 0;
    }

    >button {
        cursor: pointer;
        // display: block;
        background-color: var(--color-brand);
        color: var(--color-brand-alt);

    }

    svg {
        width: var(--icone);
        height: var(--icone);
    }

    >select,
    >input {
        flex: 1;
    }
}

.select-dropdown {
    display: inline-flex;
    @include gap(var(--marge-petite));
    position: relative;

    span {
        flex: 1;
        font: var(--graisse-gras);
        font-size: var(--taille-texte-grand);
        font-family: var(--police-sans-serif);
        color: var(--color-brand);
    }

    &:hover {

        &,
        span {
            color: var(--color-accent);
        }
    }

    @include paddingBlock(var(--marge-mini));

    select {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }

    svg {
        width: var(--icone);
        height: var(--icone);
        transform: rotate(0deg);
        transform-origin: 40% center;
        transition: transform .1s ease;
    }

    &:hover,
    &:active,
    &:focus {
        svg {
            transform: rotate(90deg);
        }
    }
}