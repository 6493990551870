@import "../inc/base";


[data-zone="recherche"] {

    // @include grid(('all':'max','row-gap':var(--marge)));

    >* {
        // @include span(('all':'max', 'grid':true));

        >*:not([data-resultats="forum"]) {
            // @include span(('all':'content'));
        }

        >[data-resultats="forum"] {
            // @include span(('all':'max'));
        }
    }

}


form[data-action="formulaire-recherche"] {
    &:not([data-fullscreen="true"]) {

        [data-action="fermer-recherche"],
        [data-zone="resultats"] {
            display: none;
        }
    }

    &[data-fullscreen="true"] {
        [data-action="fermer-recherche"] {
            position: absolute;
            top: 0;
            right: 0;
            width: var(--icone-petit);
            height: var(--icone-petit);
            transform: translate(-50%, 50%);
        }

        background-color: rgba(0, 0, 0, 0.5);

        >div {
            position: absolute;
            inset: var(--marge-exterieure-grille);
            display: flex;
            // flex-direction: column;
            // @include gap();
			@include columnGap();

            .field {}

            [data-zone="resultats"] {
                overflow-y: auto;

                [data-zone="articles"],
                [data-zone="fiches"] {
                    padding: var(--marge);
                }
            }
        }
    }

    [data-zone="resultats"] {
        color: var(--color-dark);
    }
}

[data-zone="resultats"] {
    // @include grid(('span':'content'));


    >* {
        &:empty {
            display: none;
        }

        background-color: var(--color-brand-alt);
    }
}

[data-zone="menu-slide"] {
    [data-zone="resultats"] {
        display: flex;
        // flex-direction: column;
        // @include gap();
        @include columnGap();
    }

    [data-resultats] {
        padding: var(--marge);
    }
}

[data-resultats="fiches"] {
    @include grid(('all':'content'));
    @include paddingBlock(var(--marge));

    >div {
        @include paddingBlock(var(--marge-mini));
        @include span(('span':'content'));
        overflow-x: auto;
        height: auto;
        flex-shrink: 0;
        display: flex;
        @include gap();

        >a {
            display: flex;
            // flex-direction: column;
            // @include gap(var(--marge-mini));
			@include columnGap(var(--marge-mini));
            color: var(--color-text-default);
            align-items: center;

            >figure {
                &:empty:before {
                    opacity: 0.4;
                    content: url(/img/svg/avatar.svg);
                    display: block;
                    width: 100%;
                    height: 100%;
                }

                width: var(--icone-smart);
                height: var(--icone-smart);
                margin: 0;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            h2 {
                font-size: var(--taille-texte-petit);
                text-align: center;
                margin: 0;
            }
        }

    }

    &[data-type="equipes"] {
        div>a {
            >figure {
                img {
                    object-fit: contain;
                }
            }
        }
    }

    &[data-type="joueurs"] {
        div>a {
            >figure {
                img {
                    object-fit: cover;
                }
            }
        }
    }

}

[data-zone="resultat-recherche"] {
    margin-bottom: var(--marge);
    padding-bottom: var(--marge);
    border-bottom: var(--border-size-1) solid;
    display: flex;
    @include gap();

    // @include grid(('all':'content'));

    >figure {
        flex: 0 0 var(--largeur-2-colonnes);
        margin: 0;
        // @include span(('all':2));

        >img {
            margin: 0;
            display: block;
            width: 100%;
            aspect-ratio: 4/3;
            object-fit: cover;
        }
    }

    >div {
        // @include span(('mini':2,
        //         'petit':5,
        //         'moyen':5,
        //         'grand':7));

        display: flex;
        flex-direction: column;

        >header {
            flex: 1;
            display: flex;
            // flex-direction: column;
            // @include gap(var(--marge-mini));
			@include columnGap(var(--marge-mini));

            h3 {
                font-size: var(--taille-texte-petit);
            }

            h2,
            h2 a {
                color: var(--color-brand);
            }
        }
    }
}

[data-resultats="articles"] {

    >[data-zone="resultat-recherche"] {
        >figure {}
    }

}

/*[data-zone="resultats"] {
    display: flex;
    flex-direction: column;
    @include gap();

    >* {
        &:empty {
            display: none;
        }

        background-color: var(--color-brand-alt);
        display: flex;
        @include gap();
    }

    [data-resultats="forum"] {
        display: flex;
        [data-zone="forum-section"] {
            @include marginInline(0);
        }
        [data-zone="topics"] > div{
            display: flex;
            article {
                width: 27vw;
                aspect-ratio: 4/3;                
            }
        }
    }

    [data-resultats="articles"] {
        flex-direction: column;
        @include paddingBlock(var(--marge));
    }

    [data-resultats="fiches"] {
        @include paddingBlock(var(--marge));
        overflow-x: auto;
        height: auto;
        flex-shrink: 0;

        >a {
            display: flex;
            flex-direction: column;
            @include gap(var(--marge-mini));
            color: var(--color-text-default);

            >figure {
                &:empty:before {
                    opacity: 0.4;
                    content: url(/img/svg/avatar.svg);
                    display: block;
                    width: 100%;
                    height: 100%;
                }

                width: var(--icone-grand-smart);
                height: var(--icone-grand-smart);
                margin: 0;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            h2 {
                font-size: var(--taille-texte-petit);
                text-align: center;
                margin: 0;
            }
        }


        &[data-type="equipes"] {
            >a {
                >figure {
                    img {
                        object-fit: contain;
                    }
                }
            }
        }

        &[data-type="joueurs"] {
            >a {
                >figure {
                    img {
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

*/