@import "../inc/base";

body {
    >#main {
        margin: 0 var(--marge-exterieure-grille);

        position: relative;

        >#cadre {
            position: relative;
            display: flex;
            // flex-direction: column;
            // gap: var(--marge);
			@include columnGap();
        }
    }

    overflow-x: hidden;

    >#main {
			@include moyen {
        margin-top: var(--marge);
    }
    }
}

html {

    [data-page="home"],
    [data-page="fiches"],
    [data-page="article"] {
        >#main {
            // margin-top: 0;
            // max-width: 1500px;
            // margin: 0 auto;
        }
    }

    [data-page="home"]>#main {
        >#cadre {
            @include gap(0);
        }
    }

}