@import "../inc/base";

[data-zone="partenaires"] {
    >h2 {
        display: block !important;
        color: var(--color-brand);
        font-size: var(--taille-texte-grand);
        margin: var(--marge-grande);
        margin-bottom: var(--marge);
        text-align: center;
    }

    >ul {
        border-top: var(--border-size-1) solid;

        @include moins-que-grand {
            border-bottom: var(--border-size-1) solid;
            margin-bottom: var(--marge);
        }

        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        @include tres-grand {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        @include paddingBlock(var(--marge));
        @include gap();

        >li {

            a,
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &:hover {
                opacity: 0.8;
            }
        }
    }
}