/* margins */


// masquer les images vides
img[src=""] {
    display:none !important;
}
.stabilo {
    background-color: var(--yellow-2);
}

.blurred {
    filter: blur(10px);
}

.animated-fixed {
    position: fixed;
    transition: all 0.5s ease;
}
.margin--auto {
    margin-right: auto;
    margin-left: auto;
}

.margin--top {
    margin-top: var(--marge);
}

.margin--bottom {
    margin-bottom: var(--marge);
}

.margin--bottom-zero {
    margin-bottom: 0;
}

.margin--right {
    margin-right: var(--marge);
}

.margin--left {
    margin-left: var(--marge);
}

/* align */

.align--center {
    text-align: center;
}

.align--right {
    text-align: right;
}

/* Spaced mini headings using a span inside */

spaced-title span {
    margin-right: var(--marge);
}

[data-hidden="true"] {
    display: none !important;
}

[data-visibility="hidden"] {
    display: none !important;
}

[data-position="absolute"] {
    position: absolute;
}
