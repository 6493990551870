:root
{
    --color-negative: #ff2f00;
    // --color-text-default: #293133;
    --color-text-default: #000000;
    --color-text-alt: #fff;
    --color-background: #fff;
    --color-background-selection: #fee100;
		--color-background-selection-rgb: 254, 225, 0;
    --color-base: #f5f5f7;
    --color-border: #293133;
    --color-light-rgb: 255, 255, 255;
    --color-light: #fff;
    --color-mid-rgb: 187, 187, 190;
    --color-mid: rgb(var(--color-mid-rgb));
    --color-dark: #494957;

    --color-darkest-rgb: 41, 49, 51;
    --color-darkest: rgb(var(--color-darkest-rgb));

    --color-black: #000000;

    --color-background-default:var(--color-light);
    
    /* Colours */
    --color-brand-rgb: 21, 19, 77;
    --color-brand: rgb(var(--color-brand-rgb));
    --color-brand-alt: var(--color-light);
    --color-brand-ter: var(--color-darkest);



    --color-accent-rgb: 11, 223, 171;
    --color-accent: rgb(var(--color-accent-rgb));
    --color-text-link: var(--color-brand);
    --color-text-link-active: var(--color-brand-dark);
    --color-text-link-hover: var(--color-brand-dark);
    --color-input-background: var(--color-background);
    --color-input-border: var(--color-border);
    --color-button: var(--color-brand);
    --color-button-hover: var(--color-brand-dark);
    --color-button-active: var(--color-button);
    --color-fiches-gris: #a7a7a7;
    --color-fiches-rouge: #EA2433;
    --error-color: var(--red-6);
    --warning-color: var(--yellow-6);
    --success-color: var(--green-6);
    --info-color: var(--cyan-6);
}


svg .color-mid {
    fill:var(--color-mid);
}