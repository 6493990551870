@import "../inc/base";

.footer {
  // position: relative;
  z-index: 1;
  border-bottom: 20vh solid var(--color-light);
  background-color: var(--color-brand);
  font-family: var(--police-sans-serif);

  padding: var(--marge-grande);

  > div {
    display: flex;
    // flex-direction: column;
    // @include gap();
    @include columnGap();

    &,
    a {
      color: var(--color-brand-alt);
    }

    > svg {
      fill: var(--color-brand-alt);
      width: 200px;
    }

    > ul {
      li {
        &:not(:last-child) {
          margin-right: var(--marge-petite);
        }

        display: inline-block;

        &:before {
          content: "\2B24";
          // display: inline;
          // margin-right: var(--marge-micro);
          margin-right: var(--marge-micro);
          font-size: smaller;
          height: 1rem;
          display: inline-block;
          vertical-align: text-top;
          line-height: normal;
          margin-right: var(--marge-micro);
        }

        a {
          font-weight: normal;
          font-variation-settings: "wght" 800;
        }
      }
    }

    .tendances {
      display: grid;

      @include petite-grille {
        grid-template-columns: repeat(10, [col] 1fr);
      }

      @include grande-grille {
        grid-template-columns: repeat(12, [col] 1fr);
      }

      @include gap(var(--marge));

      > div {
        grid-column: span 3;

        a {
          font-weight: normal;
          font-variation-settings: "wght" 600;
        }
      }

      p{
				font-weight: var(--font-weight-medium);
      }
    }
  }

  @include moins-que-petit {
    > div {
      padding-bottom: 3rem;
    }
  }
}

// SVG DEFS TAKES 150px at the bottom

#svgdefs {
  display: none;
}
