@import "../inc/base";


p {
	margin: 0 0 var(--marge-petite);
	font-size: var(--taille-texte-moyen-grand);
	font-weight: var(--font-weight-medium);
}

p:last-child:only-child {
	margin-bottom: 0;
}

sup {
	line-height: 0;
}