@import "../inc/base";

.grille {

    @include moins-que-moyen {
        &.trois-colonnes {
            display: flex;
            flex-direction: column;
            @include gap(var(--marge));
        }
    }

    @include grand {
        margin-right: 0 !important;
        display: grid;
        // grid-template-rows: repeat(3, 1fr);
        grid-column-gap: var(--marge);
        grid-row-gap: 0;
        // grid-auto-rows: 1fr;

        .case {}

    }

    @include petite-grille {
        grid-template-columns: repeat(2, 1fr);
    }

    @include grande-grille {
        grid-template-columns: repeat(3, 1fr);
    }
}