@include moins-que-moyen {
    .header__sticky>.header__stories {
        display: none;
    }

}

@include moyen {
    header.header>.header__stories {
        display: none;
    }
}

header.header,
.header__sticky {

    // &:not([data-fixed="true"]) {
    >.header__stories {
        [data-zone="logo"] {
            display: none;
        }
    }


    /**
     * Nav
     */
    >.header__stories {
        @include moins-que-moyen {
            display: none !important;
            // display: block;
            // order: 3;
            overflow-x: auto;
            padding-bottom: var(--marge-mini);


            [data-mode="court"]:not(:empty) {
                display: block;
                margin-top: var(--marge-micro);
                font-size: var(--taille-texte-tout-petit);

                &+[data-mode="long"] {
                    display: none;
                }
            }

            [data-mode="court"]:empty {
                display: none;

                &+[data-mode="long"] {
                    display: block;
                    margin-top: var(--marge-micro);
                    font-size: var(--taille-texte-tout-petit);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 12vw;
                    @include marginInline(auto);
                }
            }

            margin-inline:0;
            margin-block:var(--marge);

            ul {
                display: flex;
                @include gap(var(--marge));
                padding-left: var(--marge);

                li {
                    display: flex;
                    flex-direction: column;
                    @include gap(var(--marge-micro));

                    &[data-href="/paris-sportifs"] {
                        display: none;
                    }


                    // &:first-child {
                    //     margin-left: var(--marge);
                    // }

                    flex: 0 0 15vw;
                    text-align: center;
                    position: relative;

                    a {
                        span {
                            position: absolute;
                            text-indent: -100vh;
                            overflow: hidden;
                        }
                    }

                    // >a:first-child {
                    //     position: absolute;
                    //     inset: 0;
                    //     z-index: 1;
                    // }

                    &:hover a {
                        text-decoration: underline;
                    }

                    figure {
                        margin: 0;
                        width: 15vw;
                        aspect-ratio: 1;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            margin: 0;
                        }

                        &:not([data-mode="blason"]) {
                            border-radius: 50%;
                            overflow: hidden;

                            img {
                                object-fit: cover;
                            }
                        }

                    }
                }


            }
        }

        @include moyen {

            [data-mode="court"] {
                display: none;
            }

            [data-mode="long"] {
                display: block;
            }

            // order: 2;
            padding: var(--marge-mini) var(--marge-exterieure-grille);
            background: var(--color-brand-alt);

            ul {
                margin: 0;
                display: flex;
                @include gap(var((--marge)));

                li {
                    >a:first-child {
                        display: none;
                    }

                    &:nth-child(n+7) {
                        display: none;
                    }

                    flex: 1;
                    border-left: 4px solid var(--color-brand);
                    padding-left: var(--marge-mini);
                    line-height: var(--marge-petite);
                    white-space: nowrap;
                    box-sizing: border-box;

                    figure {
                        display: none;
                    }

                    a[data-mode] {
                        font-size: var(--taille-texte);

                        &:hover {
                            color: var(--color-brand);
                            cursor: pointer;
                        }

                        &:first-letter {
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}