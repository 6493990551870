[data-page="home"] .colonnes-principales>.colonne:first-child .sondage {
    border-top: 1px solid;
    padding-top: var(--marge);

    @include moyen {
        display: none;
    }
}

[data-format="sondage"][data-page="article"] {
    [data-zone="article"] {

        .auteur,
        .signature {
            display: none !important;
        }

        .sondage>span {
            display: none !important;

        }
    }
}


.sondage {
    text-wrap: pretty;
    display: flex;
    flex-direction: column;
    gap: var(--marge-mini);

    h3 {
        margin-bottom: var(--marge-mini);

        @include moins-que-moyen {
            font-size: 125%;
        }
    }

    >* {
        margin: 0;
    }

    .infos {
        display: flex;
        justify-content: flex-end;
        gap:var(--marge-petite);
        >div,>a {
            &:not(:first-child) {
                padding-left: var(--marge-petite);
                border-left: 1px solid var(--color-brand);
            }
            display: flex;
            align-items: center;
            font-family: sans-serif;
            text-transform: uppercase;
            font-size:var(--taille-texte-tout-petit);
            gap:var(--marge-micro);

            svg {
                width: 20px;
                height: 16px;
            }
        }
    }

    >span {
        text-transform: uppercase;
        font-size: var(--taille-texte-tout-petit);
        color: var(--color-brand);
    }

    .btn {
        background-color: rgba(21, 19, 77, 0.507);
        border: 0;
        border-radius: 3px;

        @include moins-que-moyen {
            font-size: 125%;
        }

    }

    .resultats {
        text-decoration: none;
        display: flex;
        gap: var(--marge-mini);
        flex-direction: column;
        margin-bottom: var(--marge-mini);

        @include moins-que-moyen {
            font-size: 125%;
        }

        >div {
            isolation: isolate;
            position: relative;
            border: 1px solid #555;
            color: #555;

            span {
                padding: var(--marge-mini);
                display: flex;
                justify-content: left;
                align-items: center;

                &:after {
                    margin-left: var(--marge-mini);
                    font-family: monospace;
                    font-size: smaller;
                    display: inline;
                    content: attr(data-votes)
                }


            }

            .indicateur {
                background-color: #ccc;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 0%;
                transition: width 1s ease-in-out;
                z-index: -1;

            }

            &[data-gagnant="true"] {
                .indicateur {
                    background-color: rgba(var(--color-brand-rgb), 1);
                }

                span {
                    color: var(--color-brand-alt)
                }
            }
        }
    }

    .choix {
        display: flex;
        gap: var(--marge-mini);

        &[data-layout="horizontal"] {
            flex-direction: column;

            .btn {
                width: 100%;
                justify-content: flex-start;
            }
        }

        margin-bottom: var(--marge-mini);
    }


    &:not([data-mode="resultats"]) {
        .resultats {
            display: none !important;
        }
    }

    &[data-mode="resultats"] {
        .choisir {
            display: none !important;
        }
    }

}

.colonnes-principales>.colonne:first-child {
    .sondage {
        padding-bottom: var(--marge);
        border-bottom: 1px solid var(--color-brand);

        &,
        button {
            font-size: 150%;
        }
    }
}