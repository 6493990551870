@import "../inc/base";

.ad {
	margin: 0 auto;
	padding: var(--marge) 0;
	max-width: 100%;
	overflow: hidden;
	text-align: center;

	>*,
	>*>* {
		max-width: initial;
		display: block;
		margin: 0 auto;
	}
}

#interstitiel {
	padding: 0;
	border: 0;
	background: transparent;
	overflow: visible;
	outline: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2rem;

	button {
		outline: 0;
		width: 100%;
		background: none;
		border: 0;
		display: flex;
		gap: 2rem;

		svg {
			fill: white
		}

		>span {
			font-size: 0.9rem;
			background-color: white;
			color: black;
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			overflow: hidden;
			height: 3rem;
			i {
				left: 0;
				height: 10px;
				position: absolute;
				background-color: #ccc;
				width: 0%;
				transition: width .5s ease;
				bottom: 0;
			}

			&:has(i) {
				color: #ccc !important;
			}
		}

		>* {
			flex: 1;
		}
	}

	&:before {
		padding: 12px;
		content: 'Publicité';
		position: fixed;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		text-align: center;
		color: white;
		font-family: sans-serif;
		text-transform: uppercase;
		font-size: 12px;
	}

	&::backdrop {
		/* From https://css.glass */
		background: rgba(0, 0, 0, 0.8);
		backdrop-filter: blur(6px);
		-webkit-backdrop-filter: blur(6px);
		border: 1px solid rgba(255, 255, 255, 0.36);
	}
}

@include moins-que-moyen {

	[data-emplacement="megaban"] {
		display: none !important;
	}
}

@include moyen {
	#interstitiel {
		display: none !important;
	}
}

/*[data-emplacement="sous-article"] {
	display: flex;
	justify-content: center;
	margin-right: var(--marge);
	// @include grid(('mini':'content'));

	// >* {

	// 	@include span(('mini':'content', 'all':('start':2, 'span':5)));
	// }

}*/

// [data-emplacement="sous-article"],
[data-emplacement="avant-commentaires"] {
	max-width: 680px;
	width: 100%;
	margin: 0 auto;
}

@include moyen {

	[data-emplacement="banniere-mobile-fixed"],
	[data-emplacement="apres-chapo-article"],
	[data-emplacement="apres-premier-article"] {
		display: none !important;
	}
}

@include moins-que-grand {
	.habillage {
		display: none !important;
	}
}

@include grand {


	[data-habillage="true"] body:not(.body-habillage) {

		// background: url("/images/ads/habillage.jpg") no-repeat top center black;
		padding-top: 210px;
	}

	[data-habillage="true"] body,
	body.body-habillage {


		>header {
			border-top: var(--marge-grande) solid var(--color-light);
		}

		>header,
		>#main,
		>footer>div {
			// border-left: var(--marge-grande) solid transparent;
			// border-right: var(--marge-grande) solid transparent;
			box-sizing: border-box;
			max-width: var(--largeur-habillage);
			margin: 0 auto;
		}

		>header,
		>#main {
			background: var(--color-light);
		}
	}


	[data-arche="true"] {
		background-color: transparent;

		body {

			// background: black;

			.habillage {
				// @include marginInline(calc(var(--marge-exterieure-grille) * -1));
				// height: 210px;
				display: block;
			}

			.crea-habillage {
				top: 50px;
				z-index: 0;
				transform-origin: top center;
				display: flex;
				justify-content: center;
				background-repeat: no-repeat;
				background-position: top center;
				width: 100%;
				height: calc(100% - 20vh);
				position: fixed;
				text-align: center;

				&.sticky {
					height: auto;

					img {
						display: block;
						margin: 0 auto;
						max-width: unset;
						width: unset;
						height: unset;
					}
				}
			}

			>#main {
				margin: 0;
				overflow: hidden;
				// background: url("/images/ads/habillage-aerstone.jpg") no-repeat top center black;
				// cursor: pointer;

				//*code ajouté par alys pour mettre le texte en mode arche sur toute la largeur
				.chapo {
					margin-right: 0;
					padding-right: var(--marge-grande)
				}

				.content {
					grid-column: span 9;
					padding-right: 1rem;

					figure {
						width: 100%;
					}
				}

				.lien-connexe {
					width: 100%;
				}

				.side {
					grid-column: span 9;
					position: relative;

					.fiches ul {
						display: flex;
						justify-content: start;
						grid-column: span 9;

						li {
							margin-right: 1em;
						}
					}
				}

				.side::before {
					left: calc(var(--marge-exterieure-grille) * -1);
					width: var(--marge-exterieure-grille);
					content: "";
					background: var(--color-brand);
					display: block;
					position: absolute;
					height: 100%;
					mask: none;
					margin: 0;
				}

				>#cadre {
					// cursor: default;
					border-top: var(--marge-grande) solid var(--color-light);
					border-left: var(--marge-grande) solid var(--color-light);
					border-right: var(--marge-grande) solid var(--color-light);
					max-width: var(--largeur-habillage);
					margin: 0 auto;
					box-sizing: border-box;
					background: var(--color-light);

					.article[data-mode="une"],
					.colonnes-principales.une-colonne-en-mobile>.colonne:first-child>.article[data-mode="auto"]:not(:first-child) {
						>div {
							header>.titre {
								font-size: var(--taille-texte-tres-grand);
							}

							p {
								font-size: var(--taille-texte-moyen);
							}
						}

					}

					article.article,
					article[data-zone="article"] {
						>header>div:not([class]) {
							flex-direction: column;
							gap: var(--marge-mini);
						}
					}

					a.lien-connexe::before,
					div.lien-connexe::before {
						left: calc(var(--marge-exterieure-grille) * -1);
						width: var(--marge-exterieure-grille);
					}

					.article[data-mode="grand"],
					.colonnes-principales.une-colonne-en-mobile>.colonne:first-child>.article[data-mode="auto"]:first-child {
						margin-left: 0;

						>div {
							padding: var(--marge) 5%;

							header>.titre {
								font-size: var(--taille-texte-tres-grand);
							}
						}
					}

					.colonnes.colonnes-petite-grande {
						.colonne {
							grid-column: span 7;
							order: 2;

							&[data-mode-articles="moyen"] {
								order: 1;
								border-right: none;

								.article>div>header>h2.titre[data-mode=court] {
									font-size: var(--taille-texte-smart);
								}
							}
						}
					}

					.colonnes.colonnes-petite-grande-inverse {
						.colonne {
							order: 1;

							&[data-mode-articles="moyen"] {
								order: 2;
							}
						}

					}

				}
			}

			.colonnes>.colonne[data-zone="breves"] h2 {
				font-size: var(--texte-moyen);
			}

			footer.footer {
				position: relative;
			}

		}
	}

	[data-arche="true"][data-user-color-scheme="dark"] body {
		>#main {
			>#cadre {
				background: var(--color-dark);
			}
		}
	}
}

@include moins-que-grand {
	.colonne[data-zone="breves"] [data-emplacement] {
		display: none !important;
	}
}

html {
	body[data-loaded="true"] {
		.userExpand {
			.spinner {
				display: none;
			}
		}
	}
}

html {
	body {
		.userExpand {
			display: none;
			overflow: hidden;
			position: relative;


			.spinner {
				display: block;
				left: 50%;
				margin-left: -15px;
				top: 50%;
				margin-top: -15px;
				position: absolute;
				animation: loading-bar-spinner 400ms linear infinite;
			}

			.spinner .spinner-icon {
				display: block;
				width: 30px;
				height: 30px;
				border: solid 4px transparent;
				border-top-color: #333;
				border-left-color: #333;
				border-radius: 50%;
			}

			@keyframes loading-bar-spinner {
				0% {
					transform: rotate(0deg);
					transform: rotate(0deg);
				}

				100% {
					transform: rotate(360deg);
					transform: rotate(360deg);
				}
			}


			.petite,
			.grande {
				position: absolute;
				display: block;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: cover;
				height: 100%;
				width: 100%;
				opacity: 0;
				transition: opacity 1s ease;
			}

			.petite {
				z-index: 2;
			}

			.grande {
				z-index: 1;
			}

			.desktop {
				display: none;

				@include moyen {
					display: block;
				}
			}

			.mobile {
				display: block;

				@include moyen {
					display: none;
				}
			}

			transition: height .5s ease;
			height: 0;
		}
	}

	&[data-expand="true"] {
		body {
			.userExpand {
				display: block;
				height: 10vh;

				.petite {
					opacity: 1;
				}
			}
		}
	}

	@include moyen {
		&[data-expand="true"] body .userExpand:hover {
			transition: height .5s ease;
			height: 60vh;

			.petite {
				opacity: 0;
			}

			.grande {
				opacity: 1;
			}
		}
	}

	&[data-expand-open="true"] body .userExpand {
		transition: height .5s ease;
		height: 60vh;

		.petite {
			opacity: 0;
		}

		.grande {
			opacity: 1;
		}
	}
}