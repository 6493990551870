.header {
	// transform: translateY(0);
	transition: transform 0.3s ease-in-out;
	padding: 0 var(--marge-exterieure-grille);
	background-color: var(--color-brand);

	// ! TO DO : scroll up : show header

	>div[data-zone="menu"] {
		@include paddingBlock(var(--header-menu-height-padding-block));
		@include grid(('all':'max'));

		>div {
			height: var(--header-menu-height);
		}

		>div[data-zone="burger-logo"] {
			display: flex;
			gap: var(--marge);
			@include span(('mini':2, 'petit': 1, 'moyen': 1, 'grand': 2));

			>div[data-zone="burger"] {
				display: none;

				@include moyen {
					display: block;
				}

				>button {
					border: 0;
					color: var(--color-brand);
					// height: var(--icone-moyen);
					height: var(--header-menu-height);
					aspect-ratio: 36.5/30.2;
					// todo margin-top: calc((var(--header-menu-height) - var(--icone)) / 2);

					@include moyen {
						display: block;
						color: var(--color-brand-alt);
					}

					background: transparent;
					padding: 0;

					svg {
						height: 100%;
						width: 100%;

						// margin: 0;
						// margin-top: calc((var(--header-menu-height) - var(--icone)) / 2);
					}
				}
			}
		}

		>nav[data-zone="liens-menu"] {
			display: none;

			@include moyen {
				display: block;
				@include span(('petit': 5, 'moyen': 7, 'grand': 8));

				// justify-content: 
				ul {
					display: flex;
					flex-direction: row;
					gap: var(--marge);

					li {
						white-space: nowrap;

						a {
							color: var(--color-text-alt);

							&:first-child {
								@include grand {
									display: none;
								}
							}

							&:last-child {
								display: none;

								@include grand {
									display: inline;
								}
							}
						}
					}
				}
			}

		}

		>div[data-zone="liens-mobile"] {
			@include span(('mini': 2, 'petit':('span':2, 'start':6)));
			display: flex;
			justify-content: flex-end;
			gap: var(--marge);

			>a {
				&:first-child {
					@include tout-petit {
						display: none;
					} 
				}
				&:nth-child(2) {
					display: none;
					@include tout-petit {
						display: block;
					}
				}
			}

			a {
				color: var(--color-brand-alt);
			}

			>div {
				width: var(--icone);
			}

			@include moyen {
				display: none;
			}
		}


		>div[data-zone="recherche-compte"] {
			display: none;

			@include moyen {
				display: block;

				@include span(('petit': 1, 'moyen': 2, 'grand': 2));

				ul {
					display: flex;
					gap: var(--marge);
					justify-content: flex-end;

					li a {
						display: block;
						color: var(--color-text-alt);

						svg {
							height: var(--header-menu-height);
							width: auto;
							margin: 0;
						}
					}
				}

			}
		}


	}

	@include moyen {
		background: var(--color-brand);
	}

	/* /le menu */
}

// @include moins-que-moyen {
// 	[data-page="article"] .header__sticky {
// 		position: static;
// 	}
// }


[data-zone="icones-sociaux"] {
	display: none;
	@import 'menu/icones-sociaux';
}