.article[data-mode="une"],
.colonnes-principales.une-colonne-en-mobile>.colonne:first-child>.article[data-mode="auto"]:not(:first-child) {
    --mode-article: 'une';

    figure[data-zone="photo"] {
        img {
            &[data-mode-article]:not([data-mode-article*="une"]) {
                display: none;
            }

            display: block;
        }
    }


    .cdf {
        display: none;
    }

    @include moyen {

        display: grid;
        gap: var(--marge);

        >figure[data-zone="photo"] {

            img {
                height: 100%;
                max-height: unset;
            }
        }

        >div {
            grid-column: span 3;
            display: flex;
            flex-direction: column;
            margin-right: var(--marge);

            header {
                // flex: 1;

                >.titre {
                    flex: 1;
                    font-size: var(--taille-texte-moyen-smart);
                }
            }
        }

        @include petite-grille {
            grid-template-columns: repeat(7, [col] 1fr);

            >figure[data-zone="photo"] {
                grid-column: span 4;
            }
        }

        @include grande-grille {
            grid-template-columns: repeat(9, [col] 1fr);

            >figure[data-zone="photo"] {
                grid-column: span 6;
            }

        }
    }

}