@import "../inc/base";

html {
    scroll-behavior: smooth;
}

[data-scroll] {
    scroll-behavior: smooth;
}

[data-flip="horizontal"] {
    transform: rotateY(180deg);
}

template[data-name] {
    display: none;
}

code.key {
    background-color: #ccc;
    border: 2px solid #eee;
    border-bottom-color: #bbb;
    border-right-color: #bbb;
    color: black;
    padding: 0 12px;
    margin: 2px 0;
    display: inline-block;
    border-radius: 2px;
}

@-ms-viewport {
    width: device-width;
}

@-o-viewport {
    width: device-width;
}

@viewport {
    width: device-width;
}

::-moz-selection {
    background-color: var(--color-background-selection);
    text-shadow: none;
}

::selection {
    background-color: var(--color-background-selection);
    text-shadow: none;
}
/*::-webkit-scrollbar-track {
    margin: var(--marge-mini);
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--color-mid);
}

html,
body {
    scrollbar-color: var(--color-mid) transparent;
    scrollbar-width: thin;
}*/
@include moyen {
    [data-scroll-fixed][data-fixed="true"] {
        position: fixed;
        top: 0;
        z-index: 8888;
    }
}

@include moins-que-moyen {
    [data-masque="true"] {
        display: none;
    }

    [data-scroll-fixed] {
        &[data-fixed="true"] {
            &[style*="height"] {
                height: initial !important;
            }
            &[style*="width"] {
                width: initial !important;
            }

        }
    }
}