/* =advertising */

[src*='tmp-ad'] {
    display: none;
}

@media (min-width: 64em) {
    [src*='tmp-ad'] {
        display: block;
    }
}

/* =img */

img {
    max-width: 100%;
    border-width: 0;
    vertical-align: middle;
}

p > img[width] {
    max-width: 100%;
    height: auto;
}

img[width],
img[height] {
    max-width: none;
}

/* figure */

figure {
    margin: 0 0 var(--marge-petite) 0;
}

figure img {
    margin-bottom: var(--marge-micro);
}

figcaption {
    font-family: var(--police-sans-serif);
    font-size: var(--taille-texte-tout-petit);
    font-style: normal;
}

/* figures (alt) */

figure[class*='alt-'] {
    display: flex;
}

/* figure horizontal */

figure[class*='horizontal'] div {
    flex: 2;
}

figure[class*='horizontal'] img {
    margin-bottom: 0;
}

figure[class*='horizontal'] figcaption {
    flex: 1;
}

figure.alt-horizontal {
    flex-direction: row;
}

figure.alt-horizontal div {
    margin-right: var(--marge-petite);
}

figure.alt-horizontal--reverse {
    flex-direction: row-reverse;
}

figure.alt-horizontal--reverse div {
    margin-left: var(--marge-petite);
}

/* figure vertical */

figure.alt-vertical {
    flex-direction: column;
}

figure.alt-vertical img {
    margin-bottom: var(--marge-petite);
}

figure.alt-vertical--reverse {
    flex-direction: column-reverse;
}

figure.alt-vertical--reverse img {
    margin-bottom: 0;
}

figure.alt-vertical--reverse figcaption {
    margin-bottom: var(--marge-petite);
}


.foot_left_arrow,
.foot_right_arrow {
    fill: var(--color-brand);
}