a {
  // 
  text-decoration: none;
  color: var(--color-brand);
}

a:hover {
  text-decoration: underline;
  color: var(--color-text-link-hover);
}

a:focus {
  outline: thin dotted;
  color: var(--color-text-link-active);
}

a:hover,
a:active {
  outline: 0;
}

a:link {
  //    transition: color var(--duration-quickly) ease;
}

// liens dans les articles
form.form p,
article p,
#cadre > p,
#cadre .colonne > div > p,
header p {
   a:not([class]) {
    // border-bottom: 1px solid rgba(var(--couleur-lien-rgb, var(--color-brand-rgb)), 0.6);
    box-shadow: inset 0 -2px 0 rgba(var(--couleur-lien-rgb, var(--color-brand-rgb)), 0.6);
    color: inherit;
    transition: all 0.5s cubic-bezier(0.33, 0.66, 0.66, 1);
    text-decoration: none !important;
    background: rgba(var(--color-brand-rgb), 0);

    &:hover {
      background: var(--color-brand);
      color: var(--color-brand-alt);
      text-decoration: none !important;
      border-bottom: 0;
      box-shadow: 0;
  
    }
  }
}
