hr {
    clear: both;
    display: block;
    margin: var(--marge-petite) 0;
    padding: 0;
    height: 1px;
    border: 0;
    border-bottom: var(--border-size-1) solid var(--color-border);
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
    hr {
        border-bottom-width: var(--border-size-1);
    }
}

@media screen and (min-width: 48em) {
    .hr--spacer {
        border-bottom-width: 0;
    }
}
