body:not([data-connected-admin="true"]) {
    
    [data-if-connected-admin="true"]:not([data-if-connected-id]) {
        display: none;
    }

    [data-if-connected-admin="false"] {
        display: block;
    }
}

body[data-connected-admin="true"] {
    [data-if-connected-admin="false"]:not([data-if-connected-id]) {
        display: none;
    }

    [data-if-connected-admin="true"] {
        display: block;
    }
}

body:not([data-connected="true"]) {
    [data-if-connected-id], [data-if-connected="true"] {
        display: none;
    }

    [data-if-connected="false"] {
        display: block;
    }
}

body[data-connected="true"] {
    [data-if-connected-id="false"],
    [data-if-connected="false"] {
        display: none;
    }

    [data-if-connected="true"] {
        display: block;
    }
}

body:not([data-connection-checked="true"]) {
    [data-if-connection-checked="true"] {
        display: none;
    }

    [data-if-connection-checked="false"] {
        display: block;
    }
}
body[data-connection-checked="true"] {
    [data-if-connection-checked="false"] {
        display: none;
    }

    [data-if-connection-checked="true"] {
        display: block;
    }
}


