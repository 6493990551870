@import "../inc/base";


ul.article-actions {
    --couleur-svg-fond: var(--color-brand);
    --couleur-svg-texte: var(--color-brand-alt);

    position: relative;
    z-index: 3;
    display: flex;
    @include gap(0);
    margin: 0;
    // margin-top: var(--marge-petite);
    // margin-bottom: var(--marge-petite);

    >li {
        &.duree {
            flex: 1;
        }

        >a {
            display: block;


            >svg {
                width: 38px;
                height: 28px;
                color: var(--couleur-svg-texte);
                fill: var(--couleur-svg-fond);

                // path {
                //     fill: var(--color-brand);
                // }

                // text {
                //     fill: var(--color-brand);
                // }
            }
        }
    }
}


[data-value-svg] {
    position: relative;

    &:after {
        content: attr(data-value-svg);
        position: absolute;
        top: 0;
        right: 0;
        background-color: var(--couleur-svg-texte);
        border: 1px solid var(--couleur-svg-fond);
        border-radius: 50%;
        color: var(--couleur-svg-fond);
        font-family: var(--police-sans-serif);
        font-size: var(--taille-texte-micro);
        font-weight: var(--font-weight-bold);
        width: 20px;
        height: 20px;
        display: grid;
        place-items: center;
    }
}



[data-mode="breve"],
[data-zone="breves"] {
    [data-value-svg] {
        --couleur-svg-fond: var(--color-brand);
        --couleur-svg-texte: var(--color-brand-alt);

        &:after {
            font-size: var(--taille-texte-tout-petit);
            width: 30px;
            height: 30px;


        }
    }
}