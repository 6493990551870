@import "../inc/base";

.colonnes-principales {
    >.colonne {
        >h1 {
            margin-top: var(--marge);
        }

        .liste-articles {
            display: flex;
            flex-direction: column;
            @include gap(var(--marge-grande));

            @include moyen {

                flex-direction: row;
                flex-wrap: wrap;

                width: 100%;
                @include gap();

                .article {
                    flex: 0 1 calc(50% - var(--marge));
                    border-bottom: 1px solid;
                    padding-bottom: var(--marge);

                    &:first-of-type {
                        flex: 0 1 100%;
                    }

                    &:not(:first-of-type) {
                        [data-zone="photo"] {
                            aspect-ratio: 16/9;
                        }
                    }
                }
            }
        }

        // .article {
        //     padding-bottom: var(--marge);
        //     border-bottom: var(--border-size-3) solid var(--color-brand);
        // }
    }
}