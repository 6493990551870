/**
* Icones à droite
*/
& {
	display: flex;

	a {
		display: block
	}

	svg {
		height: var(--header-menu-height);
		width: auto;
		margin: 0;

		// margin-top: calc((var(--header-menu-height) - var(--icone)) / 2);
	}

	// @include grande-grille {
	// padding-left: var(--marge-exterieure-grille);
	// }


	>ul {
		flex: 1;
		margin: 0;
		display: flex;
		content: 'test';
		@include gap(var(--marge-mini));

		@include grande-grille {
			// @include gap(0);
		}

		justify-content: flex-end;

		li {
			text-align: center;

			@include grande-grille {
				// flex: 1;
			}

			// &:last-child {
			//     padding-right: var(--marge);
			// }
		}
	}
}