:root {
	/* Polices */
	--police-serif: 'bitter', var(--font-serif);
	--police-sans-serif: 'roboto', var(--font-sans);

	/** graisses **/
	--font-weight-medium: var(--font-weight-5);
	--font-weight-bold: var(--font-weight-7);
	--font-weight-bolder: var(--font-weight-9);
	--graisse-gras: 'wght' var(--font-weight-bold);
	--graisse-tres-gras: 'wght' var(--font-weight-bolder);

	/** tailles de textes **/
	--facteur-taille-texte: 0.85;
	--taille-texte-micro: var(--font-size-00);
	--taille-texte-tout-petit: var(--font-size-0);
	--taille-texte-petit: var(--font-size-1);
	--taille-texte: calc(var(--facteur-taille-texte) * var(--font-size-2));
	--taille-texte-moyen: calc(var(--facteur-taille-texte) * var(--font-size-2));
	--taille-texte-moyen-grand: calc(var(--facteur-taille-texte) * var(--font-size-3));
	--taille-texte-grand: calc(var(--facteur-taille-texte) * var(--font-size-4));
	--taille-texte-tres-grand: calc(var(--facteur-taille-texte) * var(--font-size-5));
	--taille-texte-tres-moyen-grand: calc(var(--facteur-taille-texte) * var(--font-size-5) * 1.25);
	--taille-texte-tres-tres-grand: calc(var(--facteur-taille-texte) * var(--font-size-6));
	--taille-texte-tres-tres-grand-smart: calc(var(--font-size-fluid-3) * 1.9);
	--taille-texte-tres-grand-smart: calc(var(--font-size-fluid-3) * 1.5);
	--taille-texte-grand-smart: var(--font-size-fluid-3);
	--taille-texte-moyen-smart: calc(var(--font-size-fluid-2) * 1.25);
	--taille-texte-smart: var(--font-size-fluid-2);
	--taille-texte-petit-smart: var(--font-size-fluid-1);
	// --taille-texte-clamp-petit: var(--size-fluid-1);
	--hauteur-ligne-petite: var(--font-lineheight-0);
	--hauteur-ligne: var(--font-lineheight-1);
	--texte-grand: 5.2vmin;
	--texte-moyen: 3vmin;
}

// BITTER
@font-face {
	font-family: 'bitter';
	src: url('/fonts/bitter/bitterpro-black-webfont.woff') format('woff'), url('/fonts/bitter/bitterpro-black-webfont.woff2') format('woff2');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'bitter';
	src: url('/fonts/bitter/bitterpro-blackitalic-webfont.woff') format('woff'), url('/fonts/bitter/bitterpro-blackitalic-webfont.woff2') format('woff2');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'bitter';
	src: url('/fonts/bitter/bitterpro-bold-webfont.woff') format('woff'), url('/fonts/bitter/bitterpro-bold-webfont.woff2') format('woff2');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'bitter';
	src: url('/fonts/bitter/bitterpro-bolditalic-webfont.woff') format('woff'), url('/fonts/bitter/bitterpro-bolditalic-webfont.woff2') format('woff2');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'bitter';
	src: url('/fonts/bitter/bitterpro-medium-webfont.woff') format('woff'), url('/fonts/bitter/bitterpro-medium-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'bitter';
	src: url('/fonts/bitter/bitterpro-mediumitalic-webfont.woff') format('woff'), url('/fonts/bitter/bitterpro-mediumitalic-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

// ROBOTO
@font-face {
	font-family: 'roboto';
	src: url('/fonts/roboto/roboto-black-webfont.woff') format('woff'), url('/fonts/roboto/roboto-black-webfont.woff2') format('woff2');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'roboto';
	src: url('/fonts/roboto/roboto-blackitalic-webfont.woff') format('woff'), url('/fonts/roboto/roboto-blackitalic-webfont.woff2') format('woff2');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'roboto';
	src: url('/fonts/roboto/roboto-bold-webfont.woff') format('woff'), url('/fonts/roboto/roboto-bold-webfont.woff2') format('woff2');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'roboto';
	src: url('/fonts/roboto/roboto-bolditalic-webfont.woff') format('woff'), url('/fonts/roboto/roboto-bolditalic-webfont.woff2') format('woff2');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'roboto';
	src: url('/fonts/roboto/roboto-medium-webfont.woff') format('woff'), url('/fonts/roboto/roboto-medium-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'roboto';
	src: url('/fonts/roboto/roboto-mediumitalic-webfont.woff') format('woff'), url('/fonts/roboto/roboto-mediumitalic-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}