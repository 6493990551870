@import "../inc/base";

.onglets-container:not(nav),
nav.onglets-container ul,
.onglets-page {
	display: flex;
	// justify-content: space-between;
	flex-wrap: nowrap;

	overflow-x: auto;

	@include moyen {
		overflow: hidden;

		&:has(a:hover) {
			overflow-x: auto;
		}
	}

	flex-direction: row;
	text-transform: uppercase;
	transition: all 0.3s;
	background-color: var(--color-brand);

	@include moyen {
		margin: 2.5rem 1rem 2.5rem 0;
	}

	>span,
	>li {
		display: block;
		padding: 1rem;

		a {
			padding: 1rem;
			color: var(--color-text-alt);


		}

		&.active {
			background-color: var(--color-text-alt);
			transition: all 0.3s;

			a {
				color: var(--color-brand);
				text-decoration: none;
			}
		}

		&:hover {
			a {
				opacity: .5;
				text-decoration: none;
			}
		}
	}
}

.onglets-fiche,
.onglets-page {}