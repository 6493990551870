@import "../inc/base";

.notification {
    position: relative;
    padding: var(--marge);
    @include marginBlock(var(--marge));
    display: flex;
    align-items: center;
    @include gap(var(--marge-micro));
    color: var(--color-light);
    background-color: var(--info-color);

    a {
        color: inherit;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
            opacity: 0.8;
        }
    }
    &[data-close="false"] {
        >button {
            display: none;
        }
    }

    &[data-type="error"] {
        background-color: var(--error-color);
    }

    &[data-type="warning"] {
        background-color: var(--warning-color);
        color: var(--color-dark);
    }

    &[data-type="success"] {
        background-color: var(--success-color);
    }

    >div {
        font-size: var(--taille-texte);
        flex: 1;
        font-family: var(--police-sans-serif);
        display: flex;
        flex-direction: column;
        @include gap(var(--marge-micro));


        >* {
            &:not(div) {
                flex: 1 0 auto;
            }

            &:empty {
                display: none;
            }

            font-size: inherit;
            margin: 0;
        }
    }

    >button {
        color: inherit;
        width: var(--icone);
        height: var(--icone);
    }

    &[data-position="fixed"] {
        margin: 0;
        position: fixed;
        bottom: var(--marge-grande);
        left: var(--marge-grande);
    }

    transition: all .5s ease-in-out;

    [data-transition="fade"] {
        opacity: 1;

        &[data-active="false"] {
            opacity: 0;
        }
    }

    &[data-transition="slide"] {
        transform: translateX(0);

        &[data-active="false"] {
            transform: translateX(calc(-100% - var(--marge-exterieure-grille)));
        }
    }

    &[data-transition="fall"] {
        transform: translateY(0);

        &[data-active="false"] {
            transform: translateY(calc(100% + var(--marge-exterieure-grille)));
        }
    }
}

[data-zone="notifications"] {
    z-index: 10000000000000000;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    @include petit {
        bottom: var(--marge-petite);
        left: var(--marge-petite);
        right: initial;
    }

    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
    @include gap(var(--marge-micro));

    .notification {
        margin: 0;
        padding: var(--marge-mini);

        &:nth-child(2) {
            opacity: .8;
        }

        &:nth-child(3) {
            opacity: .7;
        }

        &:nth-child(4) {
            opacity: .5;
        }

        &:nth-child(5) {
            opacity: .3;
        }

        &:nth-child(n+5) {
            display: none;
        }
    }

}