h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0 0 var(--marge-petite);
	font-family: var(--police-sans-serif);
	font-weight: normal;
	font-weight: 900;
	line-height: var(--hauteur-ligne-petite);
}

header {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		hyphens: manual;
		b {
			
		}
	}

}