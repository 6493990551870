html {
    background-color: var(--color-background);
    -webkit-text-size-adjust: 100%;
}


body {
    margin: 0;
    padding: 0;
    font-family: var(--police-serif);
    font-feature-settings: 'liga'1;
    font-size: var(--taille-texte);
		font-weight: var(--font-weight-medium);
    line-height: var(--hauteur-ligne);
    color: var(--color-text-default);
    background-color: var(--color-background-default);
}
@supports (font-variant-ligatures: common-ligatures) {
    body {
        font-feature-settings: normal;
        font-variant-ligatures: common-ligatures;
    }
}