@import "../inc/base";

.lecteur-videos {
    display: flex;
    flex-direction: column;
    gap: var(--marge);

    >h2 {
        font-size: var(--taille-texte-smart);
        color: var(--color-brand)
    }

    .video-principale {
        //
    }

    >.videos {
        display: flex;
        gap: var(--marge);
        height: 200px;

        >div {
            flex: 1;
            position: relative;

            @include petite-grille {
                &:nth-of-type(2) {
                    display: none;
                }
            }
        }
    }

    >div {
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 128px;
            min-height: 128px;
            width: 20%;
            height: 20%;
            transform: translate(-50%, -50%);
            fill: var(--color-light);
            opacity: .8;
        }
    }

}