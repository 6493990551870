@import "../inc/base";

:root {
	--header-menu-height: 25px;
	--header-menu-height-padding-block:8px;

	@include tres-grand {
		--header-menu-height: 30px;
		--header-menu-height-padding-block:10px;
	}

	//     --header-menu-spacing-left: 1.9vmax;
	//     --header-menu-spacing-right: .6vmax;
	//     --header-menu-spacing-small: 6vmax;
	//     --header-menu-spacing-large: 8vmax;
	//     --header-menu-spacing: 20px;
}

body {
	header.header, .header__sticky {
		transition: transform 0.2s ease-in-out, opacity .2s ease;
		// background-color: var(--color-light);
		// z-index: 10000000000000000;
	}
}

body[data-page="page"] {
	.colonnes-principales>.colonne:first-of-type {
		padding-top: var(--marge);
	}

	@include grand {
		.colonnes-principales>.colonne:first-of-type {
			padding-top: var(--marge);
			padding-right: var(--marge);
		}
	}
}

header.header, .header__sticky {
	font-size: var(--taille-texte-petit);
	font-family: var(--police-sans-serif);

	a {
		font-weight: var(--font-weight-bold);
		// font-variation-settings: var(--graisse-gras) !important;
		font-weight: var(--font-weight-bold);

		&:hover {
			text-decoration: underline;
		}
	}

	// display: flex;
	// flex-direction: column;
	// [data-zone="icones-sociaux"] {
	//     padding-right: var(--marge-exterieure-grille);
	// }
}

@import 'header/menu';
@import 'header/stories';
@import 'header/logo';
@import 'header/menu-slide';