/* ol */

ol {
    list-style-type: none;
    counter-reset: ol-counter;
    margin-left: 0;
    padding-left: 0;
    font-size: var(--taille-texte);
}

ol li {
    counter-increment: ol-counter;
    margin-left: -1rem;
}

ol li:before {
    margin-right: var(--marge);
    content: counter(ol-counter);
    color: var(--color-text-default);
}

/* ul */

ul {
    list-style-type: none;
    margin: 0;
    padding-left: 0;
    font-size: var(--taille-texte);
    // cursor: pointer;
}

.content>ul {
    font-size: var(--taille-texte-moyen-grand);
}

/*ul li:before {
    content: '• ';
    color: var(--color-brand);
}*/

li>ul,
li>ol {
    margin: var(--marge-petite) 0 var(--marge-petite) var(--marge-petite);
    padding: 0;
}

/* definition */

dl {
    font-size: var(--taille-texte);
}

dt {
    font-weight: var(--font-weight-bold);
}

dd {
    margin: 0 0 var(--marge-petite);
}

/* alt */

ul[class*='alt-'] li:before {
    content: '';
    display: none;
}

/* 48 */

@media screen and (min-width: 48em) {

    ol.alt-columns,
    ul.alt-columns {
        margin-bottom: var(--marge);
        columns: 2;
        column-gap: var(--marge);
    }
}

/* 64 */

@media screen and (min-width: 64em) {

    ol.alt-columns,
    ul.alt-columns {
        columns: 3;
    }
}

/* alt-footnotes */

.alt-footnotes li {
    margin-bottom: var(--marge-petite);
    font-size: var(--taille-texte-tout-petit);
}

/* inlialt-inlinene */

.alt-inline li {
    display: inline-block;
}

.alt-inline li:not(:last-child) {
    margin-right: var(--marge);
}

/* placeholders */

.alt--unstyled {}


.content>ul {
    display: inline-block;
    padding: 0.5em;
    padding-left: 2em;
    margin: 0.5em;


    list-style: none;

    >li {
        list-style: none;
        clear: left;
    }


    >li:before {
        content: "";
        height: 1em;
        width: 1em;
        display: block;
        float: left;
        margin-left: -1.5em;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    >li:before {
        text-align: center;
    }

    >li:before {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.5.0  SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 489.2 466.2' style='enable-background:new 0 0 489.2 466.2%3B' xml:space='preserve'%3E%3Cstyle type='text/css'%3E.st0%7Bfill-rule:evenodd%3Bclip-rule:evenodd%3B%7D%3C/style%3E%3Cpath class='st0' d='M235.7 5.6c1.6-3.5 5.1-5.6 8.9-5.6s7.3 2.2 8.9 5.6l60.7 128.4c2.4 5 5.9 9.3 10.4 12.5c4.4 3.2 9.6 5.3 15.1 6l140.9 18.1c3.8 0.5 6.9 3.1 8.1 6.7c1.2 3.6 0.2 7.6-2.6 10.2L382.7 285c-4 3.8-7 8.5-8.7 13.7c-1.7 5.2-2.1 10.8-1 16.2l26.4 139.6c0.7 3.7-0.8 7.6-3.9 9.8c-3.1 2.2-7.2 2.5-10.5 0.7l-124.6-68.2c-4.8-2.6-10.2-4-15.7-4s-10.9 1.4-15.7 4L104.2 465c-3.3 1.8-7.4 1.6-10.5-0.7c-3.1-2.2-4.6-6.1-3.9-9.8l26.4-139.6c1-5.4 0.7-11-1-16.2c-1.7-5.2-4.7-9.9-8.7-13.7L3.1 187.6c-2.8-2.6-3.8-6.6-2.6-10.2c1.2-3.6 4.3-6.3 8.1-6.7l140.9-18.1c5.5-0.7 10.7-2.8 15.1-6c4.5-3.2 8-7.5 10.4-12.5L235.7 5.6L235.7 5.6z'/%3E%3C/svg%3E");
        background-size: 60%;
        background-position: center;
        transition: background-size 0.3s;
        -webkit-transition: background-size 0.3s;
    }

}