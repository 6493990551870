@import "../inc/base";

.component-toggle {
    display: none;

    // @include petit {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 97;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    // padding: var(--marge-petite);
    background-color: black;
    color: #fff;

    select {
        background-color: var(--brand-color-dark);
        border: 0;
        font-size: var(--taille-texte-tout-petit);
        color: var(--color-alt-text);

        option {
            color: black
        }

        .theme-switcher {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
        }
    }

    // }
}

.component-group {
    align-content: flex-start;
    margin: 1rem;
    padding: 1rem;
    outline: 1px solid #ccc;

    &:first-of-type {
        padding-top: 4rem;
    }
}

.component-intro {
    position: sticky;
    top: 60px;
    padding-top: 1rem;
}


[data-rediger="true"] .component-toggle {
    display: none;
}
