.header {
	.header__logo {
		//order: 2;
		// padding: var(--marge-tres-petite) var(--marge-exterieure-grille);
		display: flex;
		@include gap(var(--marge-mini));

		>a {
			flex: 1;

			@include petit {
				flex: 0 0 var(--largeur-2-colonnes);
			}

			@include moyen {
				flex: 0 0 var(--largeur-3-colonnes);
			}

			svg {
				color: var(--color-brand-alt);
				width: auto;
				height: 100%;
				aspect-ratio: 3.42;
			}
		}

		>div {

			[data-zone="icones-sociaux"] {
				@include moins-que-petit {

					>ul>li:first-child {
						display: none;
					}
				}

				position: relative;
				top: 50%;
				transform: translateY(-50%);

				a {
					color: var(--color-brand-alt);
				}
			}

			@at-root body:not([data-header="reduced"], [data-grille="mini-grille"], [data-grille="petite-grille"]) {
				.header__logo [data-zone="icones-sociaux"] {
					display: none;
				}
			}
		}

		@include petit {
			[data-zone="icones-sociaux"] {
				display: none;
			}

			//order: 3;
			// order: 2;
		}
	}
}

[data-header="reduced"], [data-grille="mini-grille"], [data-grille="petite-grille"] {
	@include moins-que-petit {
		[data-zone="right"] {
			display: none;
		}

		[data-zone="icones-sociaux"] {
			svg {
				// height: 35px;
			}
		}

	}

	.header {
		.header__logo {
			// @include grid(('all':'max'));

			>a {
				// @include span(('all':2));

				svg {
					max-height: var(--header-menu-height);
				}
			}
		}
	}
}

/*header.header[data-fixed="true"] {
	>.header__logo {
		height: 4px;
		padding: 0;
		>* {
			display: none;
		}
	}
}*/