.article {
	--mode-article: 'normal';

	&[data-id=""] {
		display: none !important;
	}
	> figure[data-zone="photo"] {
		margin: 0;
		img {
			&[data-mode-article]:not([data-mode-article*="normal"]) {
				display: none;
			}

			display: block;
		}
	}

	&,
	>div,
	>div>header {
		display: flex;
		// gap: 0.5rem;
		// @include gap();
		@include columnGap(var(--marge));
		// flex-direction: column;
		// @include gap(var(--marge-tres-petite));
	}

	position: relative;
	margin: 0;

	// margin-bottom: var(--marge-grande);
	/* TODO fix ds sujet */
	// .cdf ul.chemin-de-fer { 
	// 	position: absolute;
	// 	visibility: hidden;
	// }
	.cdf {
		display: none;
	}

	ul.chemin-de-fer {
		li {
			font-size: var(--taille-texte-tout-petit);
		}

		li:last-of-type {
			color: inherit
		}
	}

	// &:not([data-mode="breve"]) {
	header>span.titre {
		display: none;
	}

	// }

	.titre {
		word-wrap: break-word;
	}

	>a {
		// background-color: rgba(255,0,0,.6);
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		inset: 0;
		opacity: 0;
	}



	div>header>time,
	>figure[data-zone="picto"] {
		display: none;
	}


	>div {
		header {
			// margin-bottom: var(--marge-tres-petite);

			>.titre {
				font-family: var(--police-sans-serif);
				font-weight: normal;
				font-weight: 900;
				line-height: var(--hauteur-ligne-petite);

				&[data-mode="court"] {
					display: none;
				}

				color: var(--color-brand);
				font-size: var(--taille-texte-smart);
			}
		}

		>p {
			margin: 0;
			font-weight: var(--font-weight-medium);

			&[data-mode="court"] {
				display: none;
			}

		}

	}

	&[data-mode="normal"] {
		ul.chemin-de-fer {
			// flex-wrap: wrap;

			li {
				font-size: var(--taille-texte-tout-petit);
			}
		}
	}



	@include moyen {
		figure[data-zone="photo"] {
			img {
				// filter: brightness(0) contrast(0);
			}
		}

		&:hover {
			figure[data-zone="photo"] {
				img {
					transform: scale(1.05);
					// filter: brightness(1) contrast(2);
					// filter: hue-rotate(200deg);
				}

				figcaption {
					transform: translateY(0);
					opacity: 1;
				}

			}
		}

	}
}