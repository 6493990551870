@import "../inc/base";

.module-compte {
	display: none;
}

body[data-js="true"] {
	// &[data-page="home"],
	// &[data-page="article"] {



	.module-compte {
		display: block;
		position: fixed;
		z-index: 1;
		bottom: var(--marge);
		right: var(--marge);

		.bouton-compte {
			display: block;
			position: relative;
			text-align: center;
			box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.5);
			border-radius: 50%;
			width: 62px;
			height: 62px;
			margin: 0;
			padding: 0;
			background-color: var(--color-mid);

			a {
				position: absolute;
				inset: 0;
			}

			[data-slug="avatar"] {
				fill: var(--color-text-alt);
				width: 32px;
				height: 100%;
			}

			[data-slug="tick"],
			[data-slug="lock"] {
				z-index: 1;
				position: absolute;
				width: 15px;
				height: 15px;
				top: 0;
				right: 0;
			}

			[data-slug="tick"] {
				fill: var(--success-color);
			}

			[data-slug="lock"] {
				fill: var(--color-dark);
			}
		}
		@include moins-que-petit {
				display: none;
		}
	}
}

body[data-connected="true"] .bouton-compte {
	background-color: var(--color-brand) !important;
}