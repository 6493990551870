@import "base";

body[data-menu-slide="ferme"] {
	[data-zone="menu-slide"] {
		pointer-events: none;
		opacity: 0;
	}
}

body[data-menu-slide="ouvert"] {
	overflow: hidden;

	header.header,
	.header__sticky {
		transform: translateY(-100%);
		opacity: 0;
	}

	[data-zone="menu-slide"] {
		transform: translateY(0);
	}
}

[data-menu-slide-actif="true"] {
	[data-zone="menu-slide"] {
		transform-origin: top left;
		transform: translateY(-100%);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 99;
		padding: var(--marge-exterieure-grille);
		width: calc(100% - (var(--marge-exterieure-grille) * 2));
		height: calc(100% - (var(--marge-exterieure-grille) * 2));

		>div {
			overflow: hidden;
			width: 100%;
			height: 100%;
		}

	}
}

[data-zone="menu-slide"] {
	position: relative;

	header {
		[data-action="fermer-menu-slide"] {
			display: none;
			@include moyen {
				display: block;
			}
		}
	}

	[data-action="fermer-menu-slide"] {
		width: 100%;
		height: 100%;
		color: var(--color-brand-alt);
	}

	transition: transform .5s ease-in-out;
	background-color: var(--color-brand);

	@include moins-que-moyen {
		[data-zone="recherche"] {
			display: none;
		}

		[data-action="dark-mode-toggle"] {
			button {
				margin: 0;
			}

			span {
				display: none;
			}
		}
	}

	>div {

		display: flex;
		flex-direction: column;
		@include gap(var(--marge-exterieure-grille));

		&,
		button,
		a {
			color: var(--color-brand-alt);
		}


		>header {
			@include grid(('all':'max'));

			>div {
				@include span(('all':3));
				width: auto;
				height: auto;
			}

			>button {
				@include span(('all':1, 'petit':('start':7, 'span':1), 'moyen':('start':10, 'span':1), 'grand':('start':12, 'span':1)));

			}

			>svg {
				@include span(('all':3));
			}
		}

		>[data-zone="competitions"] {
			position: relative;
			flex: 0 0 var(--icone-grand-smart);
			// padding-bottom: var(--marge-grande);
			display: flex;
			flex-direction: column;
			@include gap(var(--marge-petite));

			>div:first-of-type {
				height: var(--icone-moyen-smart);
				overflow-y: hidden;

				ul {
					height: var(--icone-moyen-smart);
					padding-bottom: 100px;
					overflow-x: auto;
					scroll-snap-type: x proximity;
					scroll-behavior: smooth;

					display: flex;
					@include gap(var(--marge-exterieure-grille));

					>li {
						scroll-snap-align: start;

						&:first-child {
							border-left: var(--border-size-3) solid var(--color-brand-alt);
							padding-left: var(--marge);
						}

						&:last-child {
							border-right: var(--border-size-3) solid var(--color-brand-alt);
							padding-right: var(--marge);
						}

						flex: 0 0 auto;

						>a {
							img {
								width: var(--icone-moyen-smart);
								height: var(--icone-moyen-smart);
								filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(287deg) brightness(108%) contrast(101%);
								object-fit: contain;
							}
						}
					}
				}
			}

			>div:last-of-type {
				display: flex;

				@include moins-que-moyen {
					// display: none;
				}

				height: var(--icone-moyen);
				justify-content: space-between;

				>button {
					width: var(--icone-moyen);
					height: var(--icone-moyen);
					display: block;

					&:first-of-type {
						transform: rotate(180deg);
					}

					svg {
						width: 100%;
						height: 100%;
					}
				}
			}
		}

		>[data-zone="liens"] {
			flex: 1;
			overflow-y: auto;

			/*          
            Masquer la scrollbar en mobile ?
            @include moins-que-petit {
                width: calc(100% + 100px);
                padding-right: 100px;
            }*/

			@include grid(('mode':'max'));


			>div {
				a {
					font-family: var(--police-sans-serif);
					font-weight: normal;
					font-variation-settings: "wght" 600;
					font-size: var(--taille-texte-petit-smart);
				}

				@include moins-que-petit {
					padding-bottom: var(--marge-tres-grande);
					border-bottom: var(--border-size-3) solid var(--color-brand-alt);

					a {
						font-size: var(--taille-texte-grand-smart);
					}
				}

				@include petit {
					min-height: 35vh;

					border-left: var(--border-size-3) solid var(--color-brand-alt);
					padding-left: var(--marge);

				}

				@include span(('mini':'max', 'petit':2, 'moyen':3, 'grand':3));

			}
		}

		>footer {
			@include grid(('mode':'max'));
			align-items: center;

			>div {

				&:first-child {
					@include span(('mini':'max',
							'petit':3,
							'moyen':3,
							'grand':4,
						));
				}

				&:last-child {
					@include span(('mini':'max',
							'petit':('span':4, 'start':4),
							'moyen':('span':4, 'start':7),
							'grand':('span':4, 'start':9),
						));
					display: flex;
					align-items: center;

					>div:first-child {
						flex: 1;
					}

					>div:not(:first-child) {
						flex: 0 0 auto;
					}

					>div:last-child {
						display: block;
						height: 100px;

						@include moyen {
							display: none;
						}
					}
				}

				[data-action="rechercher"] {
					display: flex;
					@include gap();
					font-family: var(--police-sans-serif);
					font-weight: normal;
					font-variation-settings: "wght" 600;
					font-size: var(--taille-texte-petit-smart);

					svg {
						height: var(--icone);
					}

					cursor: pointer;
				}

				[data-zone="icones-sociaux"] {}
			}
		}
	}
}