@import "../inc/base";

:root {

    /**
    Custom props du site basées sur https://open-props.style/
    Les prop suffixées "-smart" utilisent clamp() pour définir une taille minimale et une taille maximale à tous les élements fluides
    **/

    /** margins & paddings **/
    --marge-tres-micro: var(--size-1);
    --marge-micro: var(--size-1);
    --marge-tres-mini: var(--size-2);
    --marge-mini: var(--size-2);
    --marge-tres-petite: var(--size-3);
    --marge-petite: var(--size-4);
    --marge: var(--size-5);
    --marge-grande: var(--size-6);
    --marge-tres-grande: var(--size-7);
    --marge-tres-tres-grande: var(--size-8);

    /** durée en secondes */
    --duration-instantly: 0;
    --duration-immediately: 0.1s;
    --duration-quickly: 0.2s;
    --duration-promptly: 0.5s;
    --duration-slowly: 3s;


    /** tailles d'icones **/
    --icone-petit: var(--size-5);
    --icone: var(--size-6);
    --icone-moyen: var(--size-7);
    --icone-grand: var(--size-8);
    --icone-tres-grand: var(--size-11);
    --icone-smart: var(--size-fluid-5);
    --icone-moyen-smart: var(--size-fluid-6);
    --icone-grand-smart: var(--size-fluid-7);
    --icone-tres-grand-smart: var(--size-fluid-8);


    /* publicités */
    --largeur-habillage: 1110px;
    // --largeur-habillage: 1000px;


    --marge-exterieure-grille: var(--marge-tres-petite);
    --nb-colonnes: 4;
}

body {
    /* Grille */


    @include petit {
        --marge-exterieure-grille: var(--marge-tres-grande);
        --nb-colonnes: 7;
    }

    @include grand {
        --nb-colonnes: 10;
    }

    @include tres-grand {
        --nb-colonnes: 12;
    }

    --largeur-grille: calc(100vw - var(--scrollbar-width) - (2 * var(--marge-exterieure-grille)));

}

@include grand {
    html {

        &[data-arche="true"] body,
        &[data-habillage="true"] body,
        body.body-habillage {
            --nb-colonnes: 10;
            --largeur-grille: var(--largeur-habillage);
            --marge-exterieure-grille: var(--marge-grande);
            // padding: 0 var(--marge-grande);
            // margin: 0 calc((100% - var(--largeur-habillage))/2);
        }
    }
}


body {
    --largeur-marges: calc((var(--nb-colonnes) - 1) * var(--marge));
    --largeur-colonne: calc((var(--largeur-grille) - var(--largeur-marges))/var(--nb-colonnes));
    --largeur-2-colonnes: calc((var(--largeur-colonne) * 2) + (var(--marge) * 1));
    --largeur-3-colonnes: calc((var(--largeur-colonne) * 3) + (var(--marge) * 2));
    --largeur-4-colonnes: calc((var(--largeur-colonne) * 4) + (var(--marge) * 3));
    --largeur-5-colonnes: calc((var(--largeur-colonne) * 5) + (var(--marge) * 4));
    --largeur-6-colonnes: calc((var(--largeur-colonne) * 6) + (var(--marge) * 5));
    --largeur-7-colonnes: calc((var(--largeur-colonne) * 7) + (var(--marge) * 6));
    --largeur-8-colonnes: calc((var(--largeur-colonne) * 8) + (var(--marge) * 7));
    --largeur-9-colonnes: calc((var(--largeur-colonne) * 9) + (var(--marge) * 8));
    --largeur-10-colonnes: calc((var(--largeur-colonne) * 10) + (var(--marge) *9));
}

#testdiv {
    display: none;
    font-size: var(--nb-colonnes);
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    width: var(--largeur-grille);
    height: 50px;
    background-color: red;
}