@import "../inc/base";

@include grand {
	.colonnes {

		// .article * {
		//     opacity: 0.4;
		// }
		&.colonnes-principales:not(:nth-of-type(1))>.colonne:first-of-type {
			border-top: var(--border-size-1) solid;
		}

		&.colonnes-petite-grande.colonnes-petite-grande-inverse {
			border-top: var(--border-size-1) solid;
			>.colonne:last-of-type {
				border-right: var(--border-size-1) solid;
			}
		}

		&.colonnes-petite-grande:not(.colonnes-petite-grande-inverse),
		&.colonnes-principales {

			>.colonne:first-of-type {
				border-right: var(--border-size-1) solid;
			}

		}

		&.colonnes-principales>.colonne:first-of-type {}


	}


	.grille.trois-colonnes {
		position: relative;

		&:before {
			content: "";
			width: 25%;
			height: 1px;
			position: absolute;
			left: -25px;
			top: -1px;
			border-top: var(--border-size-1) solid var(--color-text-default);
		}

		border-top: var(--border-size-1) solid;

		/*        @include moins-que-tres-grand {
            >.case {

                &:nth-child(1),
                &:nth-child(2) {
                    border-right: var(--border-size-1) solid;
                }
            }
        }*/

		@include tres-grand {
			>.case:not(:nth-child(3n+3)) {
				border-right: var(--border-size-1) solid;
				margin-right: -1px;
			}
		}

		>.case {
			position: relative;

			&:after {
				bottom: 0;
				left: 0;
				right: var(--marge);
				position: absolute;
				content: '';
				display: block;
				height: 0;
				border-bottom: var(--border-size-1) solid;
			}
		}

	}
}

[data-zone="breves"] {

	>div:not(.groupe-de-breves),
	>.groupe-de-breves>div {

		>.article,
		>.ad {
			border-top: var(--border-size-1) solid;
		}
	}
}