@import "../inc/base";

/* dark theme */

// [data-user-color-scheme="dark"] {
//     --button-icon: var(--icon-sun);
//     --button-icon-filter: var(--icon-sun-filter);
//     --button-background: var(--color-light-alpha);
//     background-color: var(--color-darkest);
// }


[data-action="dark-mode-toggle"] {}

html {
    &[data-user-color-scheme="dark"] {
        [data-afficher-seulement-en-mode="clair"] {
            display: none;
        }

    }

    &:not([data-user-color-scheme="dark"]) {
        [data-afficher-seulement-en-mode="sombre"] {
            display: none;
        }
    }
}

[data-user-color-scheme="dark"] {


    --color-background-default: var(--color-darkest);
    --color-text-default: var(--color-light);
    --color-brand: var(--color-light);
    --color-brand-rgb: 255, 255, 255;
    --color-brand-alt: var(--color-darkest);

    [data-zone="menu"] {
        background: var(--color-brand-alt);

        color: var(--color-brand);
    }

    [data-zone="live"] [data-zone="messages"][data-limit="true"]:after {
        background: linear-gradient(to bottom, rgba(var(--color-darkest-rgb), 0) 0%, rgba(var(--color-darkest-rgb), 1) 57%, rgba(var(--color-darkest-rgb), 1) 100%);
    }

    .terrain-container {
        // --color-brand: green;
        // --color-brand-alt: red;
        // --color-brand-ter: orange;

    }

    tbody.tableau-formate tr:nth-child(2n+1)::after {
        background-color: var(--color-light);
        z-index: 0;
    }

    .palmares-container img {
        filter: grayscale(100%);
    }

    [data-zone="menu-slide"],
    .footer,
    .header,
    [data-zone="equipetype"] .terrain {


        --color-brand: var(--color-darkest);
        --color-brand-alt: var(--color-light);

    }

    div.input>button {
        background-color: var(--color-mid);
    }

    .footer {
        border-top: var(--border-size-3) solid;
    }

    @include moins-que-moyen {
        header.header>nav a {
            color: var(--color-brand-alt);
        }
    }

    // .article:not([data-mode="grand"]) {
    .article-actions {
        svg {

            circle:first-of-type {
                fill: var(--color-darkest);
            }

            text {
                fill: var(--color-darkest) !important;
            }

        }
    }

    // }

    /*    body {
        color: var(--color-text-alt);

        >#main,
        >#main >#cadre {
            background-color: var(--color-dark);
            border-color: var(--color-dark);
        }

        >header {
            border-color: var(--color-dark);

            >div {

                &[data-zone="menu"] {
                    background: var(--color-darkest);

                    div[data-zone="left"]>div[data-zone="defilant"]>div>ul>li>a {

                        color: var(--color-light);

                        >span {
                            color: var(--color-darkest);
                        }
                    }
                }

                &:last-child {
                    background: var(--color-darkest);

                }
            }
        }

        >header>div:last-child {
            border-bottom: var(--border-size-1) solid var(--color-light);

        }

        .article>div>header>ul,
        .article>div>header>h2 {
            color: var(--color-text-alt);
        }

        .footer {
            border-color: var(--color-text-alt);

            a {
                color: var(--color-text-alt);
            }
        }
    }*/
}