@import "../inc/base";

body[data-debug="true"] {
    &:before {

        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("/tmp/Screenshot_15.jpg") no-repeat top center;
        background-size: 100% auto;
        z-index: 100000000;
        opacity: .5;
        filter: saturate(0);
        transition: opacity 0.2s ease;
    }

    &:hover:before {
        display: none;
    }

    &:active:before {
        opacity: 1;
    }
}

body[data-page="composants"] {
    padding-top: calc(var(--marge-mini) * 10);

    #menu-composants {
        background: black;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10000;

        ul {
            display: flex;
            @include gap(var((--marge-petite)));
            padding: var(--marge-tres-micro) var(--marge-petite);

            a {
                color: white
            }

            [data-selected="true"] {
                text-decoration: underline;
            }
        }
    }
}



.dev-grille {
    pointer-events: none;
    &:before {
        position: fixed;
        top: var(--marge-exterieure-grille);
        left: 50%;
        transform: translateX(-50%);
        font-family: monospace;
        text-shadow: 1px 1px 0px white;
        padding: 3px 5px;
        content: 'Grille: Mini';
        z-index: 3;
    }

    position: fixed;

    inset: 0;
    top: 0;
    bottom: 0;
    left:0;
    right: 0;
    padding: 0 var(--marge-exterieure-grille);
    z-index: 9999;
    height: 100%;

    >div {
        height: 100%;
        width: 100%;
        content: 'debug';
        @include grid(('all':'max'));

        >div {
            height: 100%;
            display: none;
            position: relative;


            &:after {
                background: orange;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0.5;
                content: '';
                z-index: 0;
            }

            span {
                width: 100%;
                bottom: var(--marge-exterieure-grille);
                position: absolute;
                font-family: var(--police-sans-serif);
                font-weight: normal;
                font-weight: 900;
                font-size: 4vw;
                text-align: center;
                z-index: 1;
                text-shadow: 1px 1px 0px white;
            }

            &:nth-child(-n+4) {
                display: block;
            }
        }
    }

    @include grid-petite {
        &:before {
            content: 'Grille: Petite';
        }

        >div>div {
            &:after {
                background: pink;
            }

            &:nth-child(-n+7) {
                display: block;
            }
        }
    }


    @include grid-moyenne {
        &:before {
            content: 'Grille: Moyenne';
        }

        >div>div {
            &:after {
                background: lightgreen;
            }

            &:nth-child(-n+10) {
                display: block;
            }
        }
    }

    @include grid-grande {
        &:before {
            content: 'Grille: Grande';
        }

        >div>div {
            &:after {
                background: turquoise;
            }

            &:nth-child(-n+12) {
                display: block;
            }
        }
    }
}

@include grand {

    [data-arche="true"],
    [data-habillage="true"],.body-habillage {
        .dev-grille {
            //            max-width: calc(var(--largeur-habillage) - (2* var(--marge-grande)));
            //            margin: 0 auto;
            padding: 0 var(--marge-grande);
            margin: 0 calc((100% - var(--largeur-habillage))/2);
        }
    }
}

html:not([data-grid="true"]) {
    .dev-grille {
        display: none !important;
    }
}


.component-toggle {
    transition: transform 0.5s ease;
    transform: translateY(100%);

    &[data-visible="true"] {
        transform: translateY(0);
    }
}