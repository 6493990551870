@import "../inc/base";


figure[data-zone="photo"] {
    position: relative;
    overflow: hidden;
    margin: 0;


    img {
        margin: 0;
        transition: all 0.3s ease-in-out;
        transform: scale(1);
        margin: 0;
        width: 100%;
        // aspect-ratio: 16 / 9;
        object-fit: cover;
    }

    >figcaption {
        position: absolute;
        bottom: 0;
        right: 0;
        border: var(--border-size-4) solid transparent;
        max-width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        // left: 1px;
        padding: var(--marge-mini) var(--marge-petite);
        color: var(--color-dark);
        text-align: left;
        vertical-align: bottom;
        display: inline;
        white-space: nowrap;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        transform: translateY(100%);
        opacity: 0;
        z-index: 2;

        &:after {
            z-index: -1;
            position: absolute;
            content: '';
            inset: 0;
            // background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.01) 1%, rgba(0, 0, 0, 0.65) 100%);
            background: var(--color-light);
        }

    }
}

article>figure[data-zone="photo"] {
    margin-bottom: var(--marge);
}

@include moyen {
    figure[data-zone="photo"]:hover {
        >figcaption {
            transform: translateY(0);
            opacity: 1;
        }

    }


    .article figure[data-zone="photo"] {
        img {
            width: 100%;
            object-fit: cover;

            @each $nom,
            $size in $sizes {
                @media screen and (map-get($size, condition)) {
                    max-height: #{map-get($size, height)}vh;
                }
            }
        }

    }
}