.article[data-mode="petit"],
[data-mode-articles="petit"] .article {
    --mode-article: 'petit';

    @include grand {
        position: static;

        >div>p[data-mode="long"],
        >div>header>.titre[data-mode="long"],
        >div>header>ul,
        >figure[data-zone="photo"],
        >figure[data-zone="photo"] img {
            display: none;
        }

        >div>p[data-mode="court"] {
            display: block;
        }

        >div {

            // >header>h2.titre[data-mode="court"] {
            //     display: block;
            //     font-size: var(--taille-texte-grand);
            // }

            >header>h2.titre {
                display: block;
                font-size: var(--taille-texte-grand);
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            >ul {
                position: absolute;
                bottom: 0;
                right: 0;
                margin: var(--marge);

                >li:not(:first-of-type) {
                    display: none;
                }

            }
        }

        // padding-bottom: var(--marge);
        // border-bottom:var(--border-size-1) solid var(--color-border);
    }
}