@import "../inc/base";

.grille {
    @include grand {
        .case {

            >.article {

                margin-top: var(--marge);
                margin-right: var(--marge);
                margin-bottom: calc(var(--marge) * 2);
            }
        }
    }
}
.colonnes {

    @include grand {


        &.colonnes-petite-grande {
            >.colonne:first-of-type {
            }
        }
    }
}

/*@include grand {
    .colonnes {
        .colonne:not([data-zone="breves"]) {
            .article {
                margin: 0;
            }
        }

        &.colonnes-principales {
            >.colonne:last-child {
                margin-top: var(--marge);
                // todo margin-left: var(--marge);
            }
            >.colonne:first-child>.article[data-mode="grand"] {
                margin-left: calc(var(--marge-exterieure-grille) * -1);
            }

            >.colonne:first-child>.article:not([data-mode="grand"]) {
                margin: var(--marge-grande) 0;
            }
        }

        &.colonnes-petite-grande {
            >.colonne {
                padding-bottom: var(--marge-grande);
            }

            >.colonne>.article {
                margin: var(--marge-grande);

            }

            >.colonne:first-of-type>.article {
                margin-left: 0;
            }

            >.colonne:last-of-type>.article {
                margin-right: var(--marge-grande);
            }
        }


    }

    .grille {
        &.trois-colonnes {

            padding-bottom: var(--marge-grande);

            >.case>.article {
                margin: 0 var(--marge-grande);
                padding: var(--marge-grande) 0;

                &:first-child {
                    padding-top: var(--marge-grande);
                }

            }
        }

    }
}*/