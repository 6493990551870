@import "../inc/base";


.cols {
    display: flex;
    @include gap();

    .col {
        flex: 1;
    }
}

.colonnes {

    
    .colonne-title {
        display: none;
    }

    &.une-colonne-en-mobile {
        @include moins-que-grand {
            >.colonne:last-of-type {
                display: none;
            }
        }

        @include grand {
            >.colonne:first-of-type [data-zone="breves"] {
                display: none;
            }

        }
    }

    @include moins-que-grand {
        &.colonnes-petite-grande:not(.colonnes-petite-grande-inverse) {
            display: flex;
            flex-direction: column;

            >.colonne {
                &:first-of-type {
                    order: 1;
                }

                &:last-of-type {
                    order: 2;
                }
            }
        }
    }

    >.colonne {
        padding-bottom: var(--marge);

        display: flex;
        @include columnGap();
    }

    .colonne-title {
        display: none;
    }

    @include grand {
        display: grid;
        gap: var(--marge);

        .colonne-title {
            display: block;
            background: var(--color-brand);
            color: white;
            font-family: var(--police-sans-serif);
            font-weight: 400;
            font-weight: var(--font-weight-bold);
            font-size: var(--taille-texte-moyen-grand);
            padding: 7px 5px;
            padding-left: var(--marge);
        }

        .colonne-title {
            display: block;
            background: var(--color-brand);
            color: white;
            font-family: var(--police-sans-serif);
            font-weight: 400;
            font-variation-settings: var(--graisse-gras);
            font-size: var(--taille-texte-moyen-grand);
            padding: 7px 5px;
            padding-left: var(--marge);
        }


        &.colonnes-grande-petite {
            grid-template-columns: repeat(9, [col] 1fr);

            >.colonne:first-of-type {
                grid-column: span 6;
            }

            >.colonne:last-of-type {
                grid-column: span 3;
            }
        }

        &.colonnes-petite-grande:not(.colonnes-petite-grande-inverse) {

            @include petite-grille {
                grid-template-columns: repeat(7, [col] 1fr);

                >.colonne:last-of-type {
                    grid-column: span 4;
                }
            }

            @include grande-grille {
                grid-template-columns: repeat(9, [col] 1fr);

                >.colonne:last-of-type {
                    grid-column: span 6;
                }
            }

            >.colonne:first-of-type {
                grid-column: span 3;

                @include moyen {
                    padding-top: var(--marge);
                    padding-bottom: var(--marge);
                }

                padding-right: var(--marge);
            }

            >.colonne:last-of-type {
                @include moyen {
                    padding-top: var(--marge);
                    padding-bottom: var(--marge);
                }


                >* {
                    margin-right: var(--marge);
                }
            }

        }

        &.colonnes-petite-grande.colonnes-petite-grande-inverse {

            @include petite-grille {
                grid-template-columns: repeat(7, [col] 1fr);

                >.colonne:first-of-type {
                    grid-column: span 4;
                }
            }

            @include grande-grille {
                grid-template-columns: repeat(9, [col] 1fr);

                >.colonne:first-of-type {
                    grid-column: span 6;
                }
            }

            @include moyen {
                >.colonne:first-of-type {
                    order: 2;
                }

                >.colonne:last-of-type {
                    order: 1;
                }
            }

            >.colonne:last-of-type {
                grid-column: span 3;
                padding-top: var(--marge);
                padding-right: var(--marge);
                padding-bottom: var(--marge);
            }

            >.colonne:first-of-type {
                padding-top: var(--marge);
                padding-bottom: var(--marge);

                >* {
                    margin-right: var(--marge);
                }
            }

        }


        &.colonnes-principales {
            >.colonne:first-of-type {

                // todo ? overflow: hidden;
                // >div:not([data-zone]):not(.article):not(.colonnes):not(.colonne) {
                >div:not([data-zone]):not([class]) {
                    margin-top: var(--marge);

                    >div[data-zone] {
                        margin-right: var(--marge);
                        display: flex;
                        @include columnGap();
                    }
                }


            }

            >.colonne:last-of-type {
                grid-column: span 3;

            }

            @include petite-grille {
                grid-template-columns: repeat(10, [col] 1fr);
                >.colonne:first-of-type {
                    grid-column: span 7;
                    // overflow: hidden;

                }
            }

            @include grande-grille {
                grid-template-columns: repeat(12, [col] 1fr);


                >.colonne:first-of-type {
                    grid-column: span 9;

                }
            }
        }
    }
}



.full-width,
.grid-8-4 {
    padding-right: var(--marge-grande);
    position: relative;
}

@media (min-width: 64em) {
    .full-width {
        grid-column: 1 / -1;
        display: grid;
        grid-template-columns: 1fr;
        padding-right: var(--marge-grande);
    }

    .half-width {
        grid-column: 1 / -1;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: var(--marge);
        padding-right: var(--marge-grande);
    }

    .grid-8-4 {
        grid-column: 1 / -1;
        display: grid;
        grid-template-columns: 8fr 350px;
        grid-column-gap: var(--marge-exterieure-grille);
        padding-right: var(--marge-grande);
    }

}