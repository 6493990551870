.article[data-mode="moyen"],
[data-mode-articles="moyen"] .article {
	--mode-article: 'moyen';
	figure[data-zone="photo"] {
		img {
			&[data-mode-article]:not([data-mode-article*="moyen"]) {
				display: none;
			}
			display: block;
		}
	}

	@include moyen {

		>div>p[data-mode="long"],
		>div>header> {

			.titre[data-mode="long"] {
				display: none;
			}

		}

		>div>header> {

			h2.titre[data-mode="court"] {
				display: block;
				font-size: var(--taille-texte-grand);
			}
		}

		>div>p[data-mode="court"] {
			display: none;
		}

		>div>p[data-mode="long"] {
			display: block;
		}

	}
}