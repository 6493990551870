@import "../inc/base";

[data-zone="mosaique"]+.article {}

[data-zone="mosaique"] {
	--border: 1px solid var(--color-brand-alt);
	display: flex;
	flex-direction: column;
	@include gap();
	background-color: var(--color-brand);
	color: var(--color-brand-alt);
	@include paddingBlock(var(--marge));

	>ul.chemin-de-fer li {
		// font-size: var(--taille-texte-moyen-grand);
	}

	ul.article-actions {

		>li>a>svg {
			fill: var(--color-brand-alt);
			color: var(--color-brand);
		}
	}


	.article {
		position: relative;
		margin: 0;

		header .titre {
			color: inherit;
		}
	}


	margin-bottom: -1px;

	@include moins-que-grand {

		.article,
		.article>div,
		.article>div>header {
			@include gap();
		}

		@include marginInline(calc(var(--marge-exterieure-grille) * -1));
		@include paddingInline(var(--marge-exterieure-grille));

		>.article {

			.cdf,
			header ul.chemin-de-fer {
				display: none;
			}
		}

		[data-zone="articles-associes"] {

			&,
			&>li:last-of-type {
				display: flex;
				flex-direction: column;
				@include gap();
			}

			.article {


				.cdf {
					* {
						display: none;
					}

					border-bottom: var(--border);
				}

			}

		}
	}

	@include grand {
		margin-right: var(--marge);
		margin-top: var(--marge);

		>.article {

			.cdf,
			header ul.chemin-de-fer {
				display: none;
			}


		}

		@at-root {

			// case de la grille
			.trois-colonnes .case & {
				.chemin-de-fer {
					display: none;
				}

				margin-left: calc(var(--marge) * -1);
				padding-left: var(--marge);
			}

			// Dans la colonne à droite
			.colonnes.colonnes-petite-grande>.colonne>& {
				margin-right: 0;
				padding-right: var(--marge);
				margin-left: calc(var(--marge) * -1);
				padding-left: var(--marge);

			}

			// Dans la colonne à gauche
			.colonnes.colonnes-petite-grande>.colonne[data-mode-articles="moyen"]>& {
				margin-left: calc(var(--marge-exterieure-grille) * -1);
				padding-left: var(--marge-exterieure-grille);
				margin-right: calc(var(--marge) * -1);
				padding-right: var(--marge);

				>ul.chemin-de-fer li {
					font-size: var(--taille-texte-tout-petit);
				}

				.article ul.chemin-de-fer {
					display: none;
				}
			}

			// EN haut de la home
			.colonnes-principales>.colonne:first-of-type>& {
				margin-left: calc(var(--marge-exterieure-grille) * -1);
				padding-left: var(--marge-exterieure-grille);
				// padding-right: var(--marge);
			}

			.colonnes-principales>.colonne:first-of-type>& {
				>.article {
					padding-right: var(--marge);
				}

				>ul[data-zone="articles-associes"] {
					margin-right: var(--marge);
					@include grid(('all':'content'));

					>li {
						&:first-of-type {
							@include span(('all':3));
							border-right: var(--border);
							padding-right: var(--marge);
						}

						&:last-of-type {
							@include span(('grand':6, 'all':4));
							display: flex;
							@include gap();
							// padding-right: var(--marge);

							>.article {

								flex: 1 1 90%;
								min-width: 0;

								&:last-of-type {
									// margin-right: var(--marge);
								}

								&:not(:last-of-type) {
									border-right: var(--border);
									padding-right: var(--marge);
								}

							}

						}
					}
				}
			}

			.colonnes.colonnes-petite-grande>.colonne:last-of-type>& {
				>ul[data-zone="articles-associes"] {
					display: flex;
					flex-direction: column;
					@include gap();
				}

				// >ul[data-zone="articles-associes"] {
				// 	display: flex;
				// 	@include gap();
				// 	>li {

				// 		&:first-of-type {
				// 			width: calc((var(--largeur-colonne) * 2) + var(--marge));
				// 			background-color: red;
				// 		}

				// 		&:last-of-type {
				// 			flex: 1;
				// 			display: flex;
				// 			@include gap();
				// 			>* {
				// 				flex: 1;
				// 			}
				// 		}
				// 	}
				// }
			}

			// .colonnes.colonnes-petite-grande>.colonne:last-of-type>&,
			.colonnes-principales>.colonne:first-of-type>& {

				>ul[data-zone="articles-associes"] {
					border-top: var(--border);
					padding-top: var(--marge);

					>li {}


					.article {
						[data-zone="photo"] {
							object-fit: contain;
							height: 22vh;

							img {
								height: 100%;
							}
						}

						.cdf .chemin-de-fer {
							position: static;
							visibility: visible;

							li {
								font-size: var(--taille-texte-tout-petit);
							}
						}

						header .chemin-de-fer {
							display: none;
						}

						.titre {
							font-size: var(--taille-texte-tres-grand);
						}

						>div>p {
							&[data-mode="court"] {
								display: initial;
								font-size: var(--taille-texte-petit);
								display: none;
							}

							&[data-mode="long"] {
								display: none;
							}
						}
					}
				}


				>.article {
					// margin-right: calc(var(--marge)*2);

					[data-zone="photo"] {
						max-height: 50vh;
					}
				}
			}
		}
	}
}