@import "../inc/base";


button:not(.btn)[data-action] {
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    // display: block;
}

// button,
.btn {
    -webkit-appearance: none;
    display: inline-flex;
    @include gap(var(--marge-mini));
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin: 0;
    border-width: 0;
    font-family: var(--police-sans-serif);
    font-weight: var(--font-weight-bold);
    cursor: pointer;
    text-decoration: none;
    transition: var(--duration-promptly) all ease;

    border: var(--border-size-1) solid var(--color-brand);
    background-color: var(--color-brand);
    color: var(--color-brand-alt);
    &.outline {
        color: var(--color-brand);
        background-color: var(--color-brand-alt);
    }

    &:hover {
        background-color: var(--color-accent);
        color: var(--color-brand-alt);
    }

    &[data-type="cancel"] {
        border-color: var(--color-mid);
        background-color: var(--color-mid);
        color: var(--color-brand-alt);

        &.outline {
            background-color: var(--color-brand-alt);
            border-color: var(--color-dark);
            color: var(--color-dark);
        }

        &:hover {
            background-color: var(--color-accent);
            color: var(--color-brand-alt);
        }
    }

    padding: var(--marge-mini);
    font-size: var(--taille-texte-petit);
    line-height: 1;

    &.btn-small {
        padding: 0 var(--marge-mini);
        font-size: var(--taille-texte-tout-petit);
    }
}


a.btn:hover {
    text-decoration: none;
}
// button:hover,

// button:active,
.btn:active {
    background-color: var(--color-brand);
    color: var(--color-brand-alt);
    text-decoration: none;
}

// button[class*='alt-large'],
.btn[class*='alt-large'] {
    padding: var(--marge-petite) var(--marge-grande);
}

// button[class*='alt-small'],
.btn[class*='alt-small'] {
    padding: var(--marge-mini) var(--marge-petite);
}

// button[class*='alt-block'],
.btn[class*='alt-block'] {
    display: block;
    width: 100%;
    text-align: center;
}

/* Full-width */

// button[class*='cta-block'],
.btn[class*='cta-block'] {
    display: block;
    padding: var(--marge-petite);
    width: 100%;
    background-color: var(--color-brand);
    border-radius: 0;
    font-size: var(--taille-texte-moyen);
    font-variation-settings: 'wght'var(--font-weight-bold);
    text-align: center;
    text-transform: uppercase;
}

.btn>svg {
    width: var(--icone-petit);
    aspect-ratio: 1;
    color: inherit;
}



.buttons {
    display: flex;
    @include gap(var(--marge-petite));
    justify-content: right;
}
.buttons+.buttons {
    margin-top: var(--marge-petite);
}
