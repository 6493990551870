@import "../inc/base";

[data-zone="pagination"] {
    margin-top: var(--marge);
    margin-bottom: var(--marge);
    display: flex;

    @include moins-que-moyen {
        flex-direction: column;
    }

    @include gap();

    >ul {
        flex: 1;
        display: flex;
        align-items: center;
        @include gap(var(--marge-micro));

        @include moyen {
            @include gap();
        }

        >li>a[data-current="true"] {
            background-color: var(--color-brand-alt);
            color: var(--color-brand);
        }
    }

    >div {
        text-align: right;
        display: flex;
        @include gap();



        >a {
            &:first-child {
                svg {
                    transform: rotate(180deg);
                }
            }


            &:not([href]),
            &[href=""] {
                filter: grayscale(1);

                &:before {
                    position: absolute;
                    inset: 0;
                    content: '';
                    z-index: 1;
                    background-color: var(--color-light);
                    opacity: 0.5;
                }

                cursor: not-allowed;

                +a {
                    margin-left: auto;

                }

                position: relative;


            }
        }
    }
}