
.duree {
    color: var(--color-dark);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: help;
    >svg {
        width: 1rem;
        height: 1rem;
        color: var(--couleur-svg-texte);
        fill: var(--couleur-svg-fond);
        margin-right: var(--marge-mini);
    }
}