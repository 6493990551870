[data-mode-articles="breve"] {
	display: flex;
	// flex-direction: column;
	// @include gap(var(--marge-petite));
	@include columnGap(var(--marge-petite));

	@include tres-grand {
		@include columnGap();
		// @include gap(var(--marge));
	}
}

.article[data-mode="breve"],
[data-mode-articles="breve"]>.article {
	--mode-article: 'breve';

	figure[data-zone="photo"] {
		img {
			&[data-mode-article]:not([data-mode-article*="breve"]) {
				display: none;
			}

			display: block;
		}
	}

	padding-top: var(--marge-petite);

	.cdf {
		display: none;
	}

	@include moins-que-grand {
		@include grid(('all':'content'));

		>figure[data-zone="photo"] {
			@include span(('all':1));

			img {
				width: 100%;
				aspect-ratio: 1;
				object-fit: cover;
			}
		}

		>div {
			@include span(('mini':3, 'petit':6));
			display: flex;
			// align-items: center; je ne sais pas a quoi ca servait

			>header {
				>time {
					display: block;
				}
			}
		}
	}

	>figure[data-zone="photo"] {
		// display: none;
	}

	>figure[data-zone="picto"] {
		display: none;
	}

	>div>header {
		margin: 0;

		@include moins-que-grand {
			>span.titre {
				display: block;
			}

			// >span.titre[data-mode="long"] {
			// 	display: block;
			// }
		}

		@include grand {
			>span.titre {
				display: block;
			}

			// >span.titre[data-mode="court"] {
			// 	display: block;
			// }
		}

		@include grand {
			display: none;
		}


		.titre {
			font-size: var(--taille-texte-petit-smart);

			font-weight: var(--font-weight-medium);
			font-weight: var(--font-weight-medium);
		}

	}

	/*    >div {

        header {

            time,
            h2 {
                font-size: var(--taille-texte-grand);
                display: block;
            }

            h2 {
                margin-top: var(--marge-mini);

                &[data-mode="court"] {
                    display: none;
                }
            }
        }
    }*/

	>figure {
		>img {
			margin: 0;
			object-fit: cover;
			width: 100%;
			height: 100%;
		}

		>figcaption {
			display: none;
		}
	}


	>div {

		>header>ul,
		>p {
			display: none;
		}
	}

	margin:0;
	// margin-top: var(--marge);
	// margin-bottom: var(--marge);
	// padding-top: var(--marge);

	@include grand {
		display: flex;
		flex-direction: row;
		align-items: center;

		>figure[data-zone="picto"] {
			transition: transform 500ms cubic-bezier(0.465, 0.550, 0.740, -0.600);
			transition-timing-function: cubic-bezier(0.465, 0.550, 0.740, -0.600);
			transform-style: preserve-3d;
			display: block;
			flex: 0 0 var(--largeur-colonne);
			height: 60px;
			margin: 0;
			position: relative;
			// margin-left: var(--marge-petite);
			backface-visibility: hidden;


			.image,
			.image img,
			.image svg,
			.reactions {
				background-color: inherit;
				backface-visibility: hidden;
				position: absolute;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}

			.image {
				width: 60px;
				margin: 0 calc((100% - 60px) / 2);
			}

			.reactions {

				transform: rotateY(180deg) translateX(-50%);
				aspect-ratio: 1;
				height: 100%;
				width: auto;
				// max-height: 50px;

				[data-slug="reactions"] {
					position: absolute;
					// left: 50%;
					// transform: translateX(-50%);
					color: var(--color-brand-alt);
					fill: var(--color-brand);
					width: 100%;
					height: 100%;
				}
			}
		}

		&:hover {
			>figure[data-zone="picto"] {
				transform: rotateY(180deg);
				transition: transform .1s ease;
			}

			.titre {
				text-decoration: underline;
			}
		}

		>figure[data-zone="photo"] {
			display: none;
		}

		>div {
			>header {
				display: flex;
				// flex-direction: column;
				// @include gap(var(--marge-micro));
				@include columnGap(var(--marge-micro));

				time {
					display: block;
					font-size: var(--taille-texte-petit);
					font-family: sans-serif;
				}

				.titre {
					color: inherit;
					// font-size: var(--taille-texte);
					font-size: calc(var(--taille-texte) / var(--facteur-taille-texte));
					line-height: 1.3;
				}
			}
		}
	}


}