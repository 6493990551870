@import "../inc/base";

.barre-mobile {
    @include moyen {
        display: none;
    }

    --color-texte: var(--color-brand);
    --color-fond: var(--color-brand-alt);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 98;

    >div {
        display: grid;
        place-items: center;
        position: relative;

        >[data-action="fermer"] {
            top: 0;
            right: 0;
            transform: translate(-50%, -50%);
            position: absolute;
            width: 20px;
            aspect-ratio: 1;
            background-color: black;
            color: white;
            border-radius: 50%;
        }
    }

    >ul {
        border-top: 1px solid var(--color-texte);
        padding-top: var(--marge-mini);
        padding-bottom: var(--marge-mini);
        display: flex;
        background: var(--color-fond);
        overflow-x: hidden;

        >li {
            &:not(:first-of-type) {
                border-left: 1px solid var(--color-texte);

            }

            flex: 0 0 auto;
            // width: 19vw;
            flex: 1;
            text-align: center;
            display: flex;
            flex-direction: column;
            button, a {
							font-family: var(--police-serif);
                flex: 1;
                text-decoration: none;
                display: flex;
                flex-direction: column;
                gap: var(--marge-micro);
                font-size: var(--taille-texte-tout-petit);
                color: var(--color-texte);
								align-items: center;

                >figure {
                    position: relative;
                    flex: 1;
                    margin: 0 auto;
                    aspect-ratio: 1;
                    width: 6vw;
                    >span {
                        &:empty {
                            display: none;
                        }
                        position: absolute;
                        background: #ff2f00;
                        color: white;
                        border-radius: 50%;
                        height: 15px;
                        font-size: 10px;
                        display: grid;
                        place-items: center;
                        aspect-ratio: 1;
                        top: 0;
                        right: 0;
                        transform: translate(60%, -40%);
                    }
                    >svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

}