@import "../inc/base";

ul.chemin-de-fer {
  position: relative;
  // display: flex;
  // flex-wrap: wrap;
  // @include gap(var((--marge-mini)));
  z-index: 9;
  color: inherit;
  align-items: center;

  li {
    // flex: 0 0 0px;
    display: inline;
    // white-space: nowrap;
    color: inherit;
    text-transform: uppercase;
    font-size: var(--taille-texte-petit);
    font-family: var(--police-serif);
		font-weight: var(--font-weight-medium);

    @include moyen {
      font-size: var(--taille-texte-petit);
    }

    &:not(:first-child) {
      margin-left: var(--marge-micro);
    }

    &:before {
      content: "⬤";
      margin-right: var(--marge-micro);
      font-size: smaller;
      height: 1rem;
      display: inline-block;
      vertical-align: text-top;
      line-height: normal;
      margin-right: var(--marge-micro);
      // color: var(--color-brand);
      color:inherit;
    }

    &:last-of-type {

      &,
      a {}
    }
  }
}

.cdf {
  max-width: 100%;
  overflow-x: hidden;

  ul>li {
    >span {
      display: inline-block;
      max-width: 50px;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }

    &:before {
      vertical-align: super;
      font-size: var(--taille-texte-micro);
    }
  }
}